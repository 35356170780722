import React, { useContext, useState, useEffect } from 'react'
import UserContext from '../MODELS/USER/UserContext';
import UserType from '../MODELS/USER/User';
import { toast } from 'react-toastify';

interface UserCardProps {
    user: UserType;
    width: number;
    show : (caller: string) => void;
}
 
const UserCard: React.FunctionComponent<UserCardProps> = ( props ) => {

    const { users, modifyUsers } = useContext(UserContext);
    const [ formObject, setFormObject ] = useState({ id: 0, name: '', email: '', role:''})

    useEffect(() => {

    }, [props.user])

    const updateState = () => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setFormObject({
            ...formObject,
            [e.target.name]: e.target.value
          })
    };
    
    const handleForm = (action: string): void => {
        //ANY CHANGES
        if(action === 'modify'){
            let empty = true
            Object.values(formObject).map((value, index) => {
                if(typeof value !== 'number'){
                        if(value.toString().length !== 0){ empty = false }
                }
            })
            if(empty){ toast('Nem változott paraméter!')
            }else{
                modifyUsers(createModifyObject(formObject), 'modify');
                props.show('userCard')
            }
        }if(action === 'delete'){
            modifyUsers(props.user, 'delete');
            props.show('userCard')
        }

    }

    const createModifyObject = (formObject: UserType) => {
        
        let answer: UserType = { id: 0, name: '', email: ''};
        answer.id = props.user.id;
   
        formObject.name !== "" ? answer.name = formObject.name : answer.name = props.user.name
        formObject.email !== "" ? answer.email = formObject.email : answer.email = props.user.email
 
        return answer;
    }

    return ( 
        <div className = {props.width < 480 ? 'wrapper column' : 'wrapper row' } id = {props.width < 480 ? 'usercardmobile' : 'usercard' }>
            <div className = {props.width < 480 ? 'wrapper column width-100 height-70 ' : 'wrapper column width-75' }>
                <div className = 'wrapper width-100 height-50 row' >
                    <div className = 'wrapper width-20 height-70 font-300 color-800'> Név </div>
                    <input className = 'wrapper width-80 height-90 font-300 color-500 form-control admin-form-input' type="text" id="inputText" name = 'name' placeholder = {props.user.name} value = {formObject.name} required onChange={updateState()}></input>
                </div>
                <div className = 'wrapper width-100 height-50 row'>
                    <div className = 'wrapper width-20 height-70 font-300 color-800'> email </div>
                    <input className = 'wrapper width-80 height-90 font-300 color-500 form-control admin-form-input' type="email" id="inputText" name = 'email' placeholder = {props.user.email} value = {formObject.email} required onChange={updateState()}></input>
                </div>
            </div>
            <div className = {props.width < 480 ? 'wrapper width-90 height-30 row justify-evenly align-center margin-top-1' : 'wrapper width-20 height-80 column align-center margin-top-1' }>
                <button className = {props.width < 480 ? 'wrapper button width-40 height-100 color-200 justify-center font-300' : 'wrapper button width-100 height-40 color-200 justify-center margin-left-2 margin-bottom-5' } onClick = {() => handleForm('modify')}>Módosít</button>
                <button className = {props.width < 480 ? 'wrapper button width-40 height-100 color-200 justify-center font-300 ' : 'wrapper button width-100 height-40 color-200 justify-center margin-left-2' } onClick = {() => handleForm('delete')}>Töröl</button>
            </div>
        </div>
     );
}
 
export default UserCard;