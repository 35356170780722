import React, { useEffect, useState, useContext } from 'react'

import { useNavigate } from "react-router-dom";




const Program15: React.FunctionComponent = (props) => {

    const [ width, setWidth ] = React.useState(window.innerWidth);

    const navigate = useNavigate();

    useEffect(() => {

    }, [])



    return(
        <div className = 'cont row justify-center align-top overflow-xnone overflow-ynone'  id = {width < 480 ? 'backMobile' : 'back' }>
            <div className = {width < 480 ? 'wrapper column width-90 height-90 align-center overflow-xnone overflow-y padding-top-25 ' : 'wrapper column width-90 height-90 align-center margin-top-5 overflow-xnone overflow-y white'} >
                <div className= {width < 480 ? "wrapper absolute row width-20 height-5 margin-left-50 margin-top-5 justify-center" : "wrapper absolute row width-10 height-5 margin-left-5 margin-top-1 justify-center" }><button className = 'button' onClick = {() => navigate('/')}>vissza</button></div>
                {/* --- OVERFLOW WRAPPER --- */}
                <div className = {width < 480 ? 'wrapper column width-100 height-100 justify-top align-center overflow-y margin-top-20' : 'wrapper column width-70 height-90 justify-top align-center margin-top-5 padding-top-3 margin-left-15 overflow-xnone overflow-y' } >
                    {/* --- HEAD --- */}
                    <div id = {width < 480 ? 'programHeadMobile' : 'programHead' }>
                        <div className = 'wrapper column width-100 align-center justify-top overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 color-800 weight-500 text-center' : 'wrapper column width-95 height-60 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center' }>
                            Tisza-tó, Hortobágy
                            </div>
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center' : 'wrapper column align-center overflow-xnone overflow-ynone font-100 color-800 weight-500 text-center' } >
                                Időpont: 2024. 07. 06. szombat
                                <br></br>
                                Indulás 06.45-kor a Kert utcai benzinkúttól és 07.00-kor a Búzatéri parkolóból. Hazaérkezés kb 20.00-kor
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- DAY1 --- */}
                    <div id = {width < 480 ? 'programDayMobile' : 'programDay' }>
                        <div className = 'wrapper column width-100 align-center justify-left overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 weight-500 text-center' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-500 text-center' } >
                                PROGRAMOK
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-400 ' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-100 weight-400 ' } >
                                <b>Vonattal utazók esetén: 105.000 Ft/fő</b>
                                <br></br>
                                Rövid túra: 
                                <br></br>
                                Megérkezünk Poroszlóra kb. 10.30-kor a Tisza-tavi Ökocentrumhoz. Csoportos jegyet veszünk (mindent bele csomag) 65 év fölött és diákoknak 4.600 Ft, 65 év alatt 5.100 Ft. Ez benne van a részvételi díjban, külön nem kell fizetni.
                                <br></br>
                                <br></br>
                                A csoportos jegy magában foglalja:
                                <br></br>
                                - 3D filmvetítés, ami 11.00-kor kezdődik, magyar nyelvű és kb. 12 percig tart.  A teljes csapat mehet be egyszerre, a fő épület földszintjén van a helyszín.
                                <br></br>
                                <br></br>
                                - 12.00-tól kishajós kirándulás a Tisza-tavon - 1 hajóba 12 fő fér el, több hajó is rendelkezésre áll, a kirándulás az Ökocentrum kikötőjéből indul, 45 perces útvonal: Ökocentrum - Eger patak - Kis-Tisza - Solymos átjáró - Kubik gödrök - Ökocentrum túravezető segítségével ismerkedhetünk meg ezzel a gyönyörű környezettel, szinte testközelből figyelhető meg a madárvilág
                                <br></br>
                                <br></br>
                                - Megtekinhető az óriásakvárium a főépületben.  a több részből álló különleges vízalatti akváriumrendszer falán keresztül élvezhetjük a cikázó halak látványát, az igényesen kialakított dekoráció a patakok, folyók, tavak valóságos vízalatti képét idézi fel és olyan érzést kelt a látogatóban, mintha valóban a víz alatt sétálnánk
                                <br></br>
                                <br></br>
                                - Felmehetünk a körpanorámás kilátótoronyba. (kb 30 m magas, lift van, gyalog 7 emelet) A Mátra és a Bükk is látható
                                <br></br>
                                <br></br>
                                - Kimegyünk a sétányokra, a park látnivalói az „éld át a természetet” mottó jegyében születtek
                                <br></br>
                                <br></br>
                                A park keleti részén találhatók főleg az állatok, kisállatsimogató, büfé, étterem. A park nyugati részén kalandjátszótér, dísztó, nyusziudvar, poroszlói tájház, vízi játszótér, sövénylabirintus van. A szabadprogram után a megbeszélt időben találkozunk a busznál és indulunk Hortobágyra, ahol megtenkinthető a kilenclyukú-híd, csárda, kézművesudvar, bazár...
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- PAY --- */}
                    <div id = {width < 480 ? 'programPayMobile' : 'programPay' }>
                        <div className = 'wrapper column width-100 height-100 align-center justify-left overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ?  'wrapper column width-95 height-10 align-center overflow-xnone overflow-ynone font-300 weight-500 color-800 text-center' : 'wrapper column width-95 height-20 align-center overflow-xnone overflow-ynone font-300 weight-500 text-center' } >
                                Részvételi díj és jelentkezés
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 height-90 align-center overflow-xnone overflow-ynone font-300 weight-600 text-center' : 'wrapper column width-95 height-80 align-center overflow-xnone overflow-ynone font-200 weight-600 text-center' } >
                                Részvételi díj  6.700 + 4.500 Ft 65 év fölött és diákok részére
                                <br></br>
                                <br></br>
                                6.700 + 5.100 Ft 65 év alatt
                                <br></br>
                                <br></br>
                                tartalmazza a buszköltséget, belépőjegyet a Tisza tavi Ökocentrumba, hajókázást, filmvetítést és a szabadidőparkban való részvételt
                                <br></br>
                                <br></br>
                                4.000 Ft előleg fizetése a jelentkezéskor. 
                                <br></br>
                                Nagyné Ancikánál 06-20/428-0564 
                                <br></br>
                                vagy Gyüre Lászlónál  42/798-722 vagy 06-30/253-9348 
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- SKUNK --- */}
                    <div id = {width < 480 ? 'programSkunkMobile' : 'programSkunk' }>
                        <div className = 'wrapper column width-100 align-center justify-left overflow-xnone overflow-ynone' >
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-200 weight-400 text-center' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-100 weight-400 ' } >
                            Ajánlott felszerelés: túra vagy sport öltözet, túracipő vagy túrabakancs,  az időjárásnak megfelelően, hátizsák vagy válltáska, esőkabát, vagy esernyő, kalap, túrabot,  1 napi hideg élelem, 1 liter víz, egyéni gyógyszer, zseblámpa, fényképezőgép, mobiltelefon, stb.  Túráink során vadon élő állatok támadására lehet számítani, ( medve, farkas, arany sakál, büdös borz, vaddisznó, róka, szarvas, őz stb.) Az általuk okozott károkért felelősséget nem tudunk vállalni! Kérem ezen tények tudomásul vételét
                                <br></br>
                                <br></br>
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-600 text-center' : 'wrapper column width-95 height-80 align-center overflow-xnone overflow-ynone font-200 weight-600 text-center' } >
                                <br></br>
                                A programváltozás jogát fenntartjuk!
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Program15