import React, { useContext, useState, useEffect } from 'react'
import UserContext from '../MODELS/USER/UserContext';
import EmailType from '../MODELS/EMAIL/Email';
import { toast } from 'react-toastify';
import validator from 'validator';


interface NewEmailProps {
    handleNewEmail : (email: any) => void;
    width: number;
    updateShowEmail : () => void;
    
}
 
const NewEmail: React.FunctionComponent<NewEmailProps> = ( props ) => {

    const [ formObject, setFormObject ] = useState<EmailType>({ from: '', to: '', subject: '', text:''})

    useEffect(() => {

    }, [])

    const updateState = () => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setFormObject({
            ...formObject,
            [e.target.name]: e.target.value
          })
    };
    
    const handleForm = (): void => {
        if(!formObject){ 
            return 
        }else if(validator.isEmpty(formObject.subject)){
            toast('A tárgy mező nem lehet üres!')
        }else if(validator.isEmpty(formObject.text)){
            toast('A szöveg mező nem lehet üres!')
        }else{
            props.handleNewEmail(formObject)
        }

        
    }

    return ( 
        <div className = {props.width < 480 ? 'wrapper absolute width-90 height-60 row justify-center align-center margin-top-5 ' : 'wrapper absolute width-60 height-80 row justify-center align-center margin-top-5 margin-left-5 ' }>
            <div id = {props.width < 480 ? 'newemailmobile' : 'newemail' }>
            <div className = {props.width < 480 ? 'wrapper width-100 height-100 column justify-left align-center ' : 'wrapper width-100 height-100 row justify-center align-top ' }>
                    <div className = {props.width < 480 ? 'wrapper width-95 height-80 column padding-0 margin-right-2' : 'wrapper width-85 height-100 column' } >
                        <div className = {props.width < 480 ? 'wrapper width-100 height-20 font-500 color-800 row padding-left-2' : 'wrapper width-100 height-30 font-200 color-800 row padding-left-2' } >Email küldés</div>
                        <div className = {props.width < 480 ? 'wrapper width-100 height-40 column' : 'wrapper width-100 height-20 row' } >
                            <div className = {props.width < 480 ? 'wrapper width-20 height-30 font-400 color-500' : 'wrapper width-20 height-70 font-100 color-500' }> Tárgy </div>
                            <input className = 'wrapper width-100 height-50 font-300 color-500 form-control admin-form-input' type="text" id="inputText" name = 'subject' value = {formObject.subject} required onChange={updateState()}></input>
                        </div>
                        <div className = {props.width < 480 ? 'wrapper width-100 height-40 column' : 'wrapper width-100 height-20 row' } >
                            <div className = {props.width < 480 ? 'wrapper width-20 height-30 font-400 color-500' : 'wrapper width-20 height-70 font-100 color-500' }> Szöveg </div>
                            <textarea className = 'wrapper width-100 height-50 font-300 color-500 form-control admin-form-input' rows = {5} id="inputText" name = 'text' value = {formObject.text} required onChange={updateState()}></textarea>
                        </div>
                    </div>
                    <div className = {props.width < 480 ? 'wrapper width-95 height-20 row justify-evenly align-center padding-0' : 'wrapper width-15 height-60 row align-center margin-left-1 padding-top-20' }>
                        <button className = {props.width < 480 ? 'wrapper button width-40 height-40 color-200 justify-center font-200' : 'wrapper button width-100 height-50 color-200 justify-center margin-left-2 margin-bottom-10' } onClick = {() => handleForm()}>Küldés</button>
                        <button className = {props.width < 480 ? 'wrapper button width-40 height-40 color-200 justify-center font-200' : 'wrapper button width-100 height-50 color-200 justify-center margin-left-2' } onClick = {() => props.updateShowEmail()}>Mégse</button>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default NewEmail;