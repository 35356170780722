import { sendEmail } from "../TOOLS/Queries"

export const createErrorMessage = (error: any) => {
    
    let emailProps: any = 'homalyzona';
    let emailData: any = { to: 'szempontstudio@gmail.com', subject: 'HIBA a hegyibarangolas.hu oldalon!!!', text: error};
    sendEmail(emailProps, emailData)
    .then(response => { })
    .catch(err => { console.log(`SENDEMAIL: ${err.message}`)})

   
}





