import React, { useEffect, useState, useContext } from 'react'
import EventContext from '../MODELS/EVENT/EventContext';
import { useNavigate } from "react-router-dom";




const EvesProgram: React.FunctionComponent = (props) => {

    const [ width, setWidth ] = React.useState(window.innerWidth);
    const { events, event3, updateEvent3 } = useContext(EventContext);
    const navigate = useNavigate();

    useEffect(() => {

    }, [])



    return(
        <div className = 'cont row justify-center align-top overflow-xnone overflow-ynone'  id = {width < 480 ? 'backMobile' : 'back' }>
            <div className = {width < 480 ? 'wrapper column width-95 height-90 align-center overflow-xnone overflow-y padding-top-25 ' : 'wrapper column width-90 height-90 align-center margin-top-5 overflow-xnone overflow-y white'} >
                <div className= {width < 480 ? "wrapper absolute row width-20 height-5 margin-left-50 margin-top-5 justify-center" : "wrapper absolute row width-10 height-5 margin-left-5 margin-top-2 justify-center" }><button className = 'button' onClick = {() => navigate('/')}>vissza</button></div>
                {/* --- OVERFLOW WRAPPER --- */}
                <div className = {width < 480 ? 'wrapper column width-100 height-100 justify-top align-center overflow-y margin-top-20' : 'wrapper column width-70 height-90 justify-top align-center margin-top-5 padding-top-3 margin-left-15 overflow-xnone overflow-y' } >
                    {/* --- HEAD --- */}
                    <div id = {width < 480 ? 'programHeadMobile' : 'programHead' }>
                        <div className = {width < 480 ? 'wrapper column width-100 height-100 align-center justify-top overflow-xnone overflow-ynone ' : 'wrapper column width-100 align-center justify-left ' } >
                        {events.map((event, index) => 
                            <div key = { index } className = {width < 480 ? 'wrapper column width-100 align-center justify-top overflow-xnone overflow-ynone ' : 'wrapper row width-95 align-center justify-left overflow-xnone overflow-ynone margin-top-1' } >
                                <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 color-800 weight-500 text-center' : 'wrapper row width-20 height-100 justify-left align-center font-150 color-800 weight-500 ' }>
                                {event.date}
                                </div>
                                <div className = {width < 480 ? 'wrapper column width-95 height-65 align-center font-300 color-800 weight-500 text-center border-down pading-bottom-1' : 'wrapper row width-75 height-100 align-center justify-left font-150 color-800 weight-500 margin-left-2 ' } >
                                {event.name}
                                </div>
                            </div>
                        )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default EvesProgram