import { createContext } from 'react';
import UserInterface from './UserInterface';


const defaultState = {
   users: [{id: 0, name: '', email: ''}],
   updateUsers: () => {},
   modifyUsers: () => {}
};

const UserContext = createContext<UserInterface>(defaultState);

export default UserContext