import { loader } from '../TOOLS/CSVHelper';
import PhotoType from '../MODELS/PHOTO/Photo';
import { modifier, inserter } from '../TOOLS/CSVHelper';

export const loadPhotos = async () => {

    return new Promise<any>(async (resolve, reject) => {
        /*
        getData('hegyibarangolas', 'photo', '', 0)
        .then((response: any) => { 
            let answer: any
            answer = (loader(response.data, 'photo'))
            resolve(answer)
        })
        .catch(err => { reject(err)})
        */
    })
}

export const photosModifier = async (photo: PhotoType, photosShallow: PhotoType[], action: string) => {
    /*
    if(action === 'modify'){
        return new Promise<any>(async (resolve, reject) => {
            saveData(modifier(photo, photosShallow, 'photo'), 'hegyibarangolas', 'photo', 0)
            .then((response: any) => { resolve(response) })
            .catch(err => { reject(err)})
        })
    }else if(action === 'new'){
        return new Promise<any>(async (resolve, reject) => {
            saveData(inserter(photo, photosShallow, 'photo'), 'hegyibarangolas', 'photo', 0)
            .then((response: any) => { resolve(response) })
            .catch(err => { reject(err)})
        })
    }
    */
}


