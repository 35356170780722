import React, { useEffect, useState, useContext } from 'react'

import { useNavigate } from "react-router-dom";




const Program26: React.FunctionComponent = (props) => {

    const [ width, setWidth ] = React.useState(window.innerWidth);

    const navigate = useNavigate();

    useEffect(() => {

    }, [])



    return(
        <div className = 'cont row justify-center align-top overflow-xnone overflow-ynone'  id = {width < 480 ? 'backMobile' : 'back' }>
            <div className = {width < 480 ? 'wrapper column width-90 height-90 align-center overflow-xnone overflow-y padding-top-25 ' : 'wrapper column width-90 height-90 align-center margin-top-5 overflow-xnone overflow-y white'} >
                <div className= {width < 480 ? "wrapper absolute row width-20 height-5 margin-left-50 margin-top-5 justify-center" : "wrapper absolute row width-10 height-5 margin-left-5 margin-top-1 justify-center" }><button className = 'button' onClick = {() => navigate('/')}>vissza</button></div>
                {/* --- OVERFLOW WRAPPER --- */}
                <div className = {width < 480 ? 'wrapper column width-100 height-100 justify-top align-center overflow-y margin-top-20' : 'wrapper column width-70 height-90 justify-top align-center margin-top-5 padding-top-3 margin-left-15 overflow-xnone overflow-y' } >
                    {/* --- HEAD --- */}
                    <div id = {width < 480 ? 'programHeadMobile' : 'programHead' }>
                        <div className = 'wrapper column width-100 align-center justify-top overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 color-800 weight-500 text-center' : 'wrapper column width-95 height-60 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center' }>
                            Advent és édes december Tokajban és Tarcalon
                            </div>
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center' : 'wrapper column align-center overflow-xnone overflow-ynone font-100 color-800 weight-500 text-center' } >
                            Időpont: 2024. 12. 07.
                                <br></br>
                                Indulás 06.45-kor a Kert utcai benzinkúttól és 07.00-kor a Búzatéri parkolóból. Hazaérkezés kb 19.00-kor
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- DAY1 --- */}
                    <div id = {width < 480 ? 'programDayMobile' : 'programDay' }>
                        <div className = 'wrapper column width-100 align-center justify-left overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 weight-500 text-center' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-500 text-center' } >
                                PROGRAMOK
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-400 ' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-100 weight-400 ' } >
                                <b>Rövid túra </b>
                                <br></br>
                                Tarcalon az Áldó Krisztustól indulunk a bányatóig és vissza az Andrássy Rezidenciához. Túratáv 2,5- kilométer, szintemelkedés 150 méter. Egy előre nem jelzett helyen megérkezik a mikulás és kiosztja a csomagokat és egyeseknek a virgácsot valamint egy kis aperitiffel fel is megkinál bennünket.
                                <br></br>
                                <br></br>
                                <b>Hosszú túra</b> 
                                <br></br>
                                Tarcalról a piros sáv jelzésen az Áldó Krisztus szoborhoz majd a bányatóhoz megyünk.  Doba műút kereszteződés után kelet felé a piros négyzeten a Kassai-hegyhez (217 méter) majd vissza Tarcalra ahol megnézzük a zsinagógát és az Andrássy Rezidenciát. Túratáv 6 km, szintemelkedés 300 méter.
                                <br></br>
                                <br></br>
                                Ezek után Tokajban bekapcsolódunk a karácsonyi advent forgatagába és az édes december programba. A horgony csárdában egyfogásos ebédet szervezünk háromféle étellel (harcsa ha lászlé pontyhalászlé vagy babgulyás) Kérem jelentkezéskor jelezzétek hogy mit kértek. Aki nem jelzi úgy veszem hogy nem akar ebédelni, utólagos reklamáció nincs.  Az étlap és az árak a facebookon lesznek.
                                <br></br>
                                <br></br>
                                <b>Jövő évi programunk</b> 
                                <br></br>
                                <br></br>
                                <b>Húsvét Pécsváradon</b> 
                                <br></br>
                                Húsvétra sikerült Pécsváradra szállást szerezni. Azzal a feltétellel tartják majd fent a szállást, ha november 30-ig 600.000 ft- előleget átutalunk ezért fejenként 20.000 ft előleget kérek sürgősen befizetni hogy lássam mennyi a jelentkező és mennyire komolyan a  jelentkezés erre a szép programra. 
                                <br></br>
                                <br></br>
                                <b>Pünkösd Nagyatádon</b> 
                                <br></br>
                                Folyamatban van a pünkösdi szállásfoglalás is Nagyatádon a fürdővárosban innen mennénk egy napra a Plitvicai tavakhoz is. Akinek valamilyen betegségi tünete van kérem időben jelezze! Sajnos a Bánkút-Lillafüred utat is 13 fő  mondta le és nagy anyagi veszteség érte így a baráti társaságot.
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- PAY --- */}
                    <div id = {width < 480 ? 'programPayMobile' : 'programPay' }>
                        <div className = 'wrapper column width-100 height-100 align-center justify-left overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ?  'wrapper column width-95 height-10 align-center overflow-xnone overflow-ynone font-300 weight-500 color-800 text-center' : 'wrapper column width-95 height-20 align-center overflow-xnone overflow-ynone font-300 weight-500 text-center' } >
                                Részvételi díj és jelentkezés
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 height-90 align-center overflow-xnone overflow-ynone font-300 weight-600 text-center' : 'wrapper column width-95 height-80 align-center overflow-xnone overflow-ynone font-200 weight-600 text-center' } >
                                Részvételi díj  50 fő esetén 5.500 Ft
                                <br></br>
                                <br></br>
                                Jelentkezés: 3.000 Ft előleggel:
                                <br></br>
                                Nagyné Ancikánál 06-20/428-0564 
                                <br></br>
                                vagy Gyüre Lászlónál  42/798-722 vagy 06-30/253-9348 
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- SKUNK --- */}
                    <div id = {width < 480 ? 'programSkunkMobile' : 'programSkunk' }>
                        <div className = 'wrapper column width-100 align-center justify-left overflow-xnone overflow-ynone' >
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-200 weight-400 text-center' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-100 weight-400 ' } >
                            Ajánlott felszerelés: túra vagy sport öltözet, túracipő vagy túrabakancs,  az időjárásnak megfelelően, hátizsák vagy válltáska, esőkabát, vagy esernyő, kalap, túrabot,  1 napi hideg élelem, 1 liter víz, egyéni gyógyszer, zseblámpa, fényképezőgép, mobiltelefon, stb.  Túráink során vadon élő állatok támadására lehet számítani, ( medve, farkas, arany sakál, büdös borz, vaddisznó, róka, szarvas, őz stb.) Az általuk okozott károkért felelősséget nem tudunk vállalni! Kérem ezen tények tudomásul vételét
                                <br></br>
                                <br></br>
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-600 text-center' : 'wrapper column width-95 height-80 align-center overflow-xnone overflow-ynone font-200 weight-600 text-center' } >
                                <br></br>
                                A programváltozás jogát fenntartjuk!
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Program26