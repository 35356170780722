import React, { useEffect, useState, useContext } from 'react'

import { useNavigate } from "react-router-dom";




const Program24: React.FunctionComponent = (props) => {

    const [ width, setWidth ] = React.useState(window.innerWidth);

    const navigate = useNavigate();

    useEffect(() => {

    }, [])



    return(
        <div className = 'cont row justify-center align-top overflow-xnone overflow-ynone'  id = {width < 480 ? 'backMobile' : 'back' }>
            <div className = {width < 480 ? 'wrapper column width-90 height-90 align-center overflow-xnone overflow-y padding-top-25 ' : 'wrapper column width-90 height-90 align-center margin-top-5 overflow-xnone overflow-y white'} >
                <div className= {width < 480 ? "wrapper absolute row width-20 height-5 margin-left-50 margin-top-5 justify-center" : "wrapper absolute row width-10 height-5 margin-left-5 margin-top-1 justify-center" }><button className = 'button' onClick = {() => navigate('/')}>vissza</button></div>
                {/* --- OVERFLOW WRAPPER --- */}
                <div className = {width < 480 ? 'wrapper column width-100 height-100 justify-top align-center overflow-y margin-top-20' : 'wrapper column width-70 height-90 justify-top align-center margin-top-5 padding-top-3 margin-left-15 overflow-xnone overflow-y' } >
                    {/* --- HEAD --- */}
                    <div id = {width < 480 ? 'programHeadMobile' : 'programHead' }>
                        <div className = 'wrapper column width-100 align-center justify-top overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 color-800 weight-500 text-center' : 'wrapper column width-95 height-60 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center' }>
                            Sopron, Kőszeg, Burgenland és a Fertő-tó környéke
                            </div>
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center' : 'wrapper column align-center overflow-xnone overflow-ynone font-100 color-800 weight-500 text-center' } >
                                Időpont: 2024.október 19-22. 4 nap,  3 éjszaka.
                                <br></br>
                                Indulás 06.45-kor a Kert utcai benzinkúttól és 07.00-kor a Búzatéri parkolóból. Hazaérkezés kb 20.00-kor
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- DAY1 --- */}
                    <div id = {width < 480 ? 'programDayMobile' : 'programDay' }>
                        <div className = 'wrapper column width-100 align-center justify-left overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 weight-500 text-center' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-500 text-center' } >
                                PROGRAMOK
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-400 ' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-100 weight-400 ' } >
                                <b>1., nap, október 19, szombat </b>
                                <br></br>
                                Indulás 7 órakor Nyíregyházáról Budapesten keresztül Sopronba. Szállás elfoglalása a Hotel Szieszta delux kategóriás szobákban. 1-2 ágyas szobák zuhanyzóval, hűtővel, bárszekrénnyel. Majd séta a Sördombházi kilátóhoz ami kb 30 perc. Utána séta a Lővérekhez ami kb 2 óra időtartam. Este büfévacsora bő kinálattal 18 órakor.
                                <br></br>
                                <br></br>
                                <b>2., nap, október 20, vasárnap</b> 
                                <br></br>
                                Svédasztalos reggeli 7 órakor, utána városnézés Sopronban idegenvezetővel. Ezután átmegyünk Burgenlandba várlátogatásra. A Fraknói várban idegenvezetővel nézzük meg a szépen berendezet vár gazdag látnivalóit. Ezután megyünk a Lánzséri várromhoz ahol szerény belépő van. Este 18 órakor vacsora.
                                <br></br>
                                <br></br>
                                <b>3., nap, október 21, hétfő</b> 
                                <br></br>
                                Reggeli után indulás Kőszegre ahol idegenvezetővel városnézésen veszünk részt, utána rövid túra lehetőség. Kőszeg - Kálvária - Trianoni kereszt. Túratáv 3 km, szintemelkedés 120 m. Utána megnézzük a Lékai várat. Este 18 órakor vacsora.
                                <br></br>
                                <br></br>
                                <b>4., nap, október 22, kedd</b> 
                                <br></br>
                                Reggeli 7 órakor utána bepakolás a buszba hazautazáshoz. Közben  látogatás a fertőrákosi kőfejtőben és kőszinházban. Amennyiben lehetséges sétahajózás a fertő tavon az osztrák oldalon. Ha ez nem jön össze akkor  a Nagycenki Széchenyi múzeumot nézzük meg. Hazaérkezés kb 20 órakor, útközben a pihenőnél ebédelési lehetőség van.
                                <br></br>
                                <br></br>
                                A határátlépéshez érvényes személyi igazolvány vagy útlevél szükséges, illetve ajánlott 2 napra kiegészítő baleset- és poggyászbiztosítás. Ajánlott költőpénz: 35-40 Euró.
                                <br></br>
                                <br></br>
                                <b>Fraknói várbelépők: </b> 
                                <br></br>
                                idegenvezetéssel: felnőtt 22 Euró, nyugdíjas, diák: 18 Euró.
                                <br></br>
                                kombinált jegy kincstárlátogatással: felnőtt 35 Euró, nyugdíjas, diák: 32 Euró
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- PAY --- */}
                    <div id = {width < 480 ? 'programPayMobile' : 'programPay' }>
                        <div className = 'wrapper column width-100 height-100 align-center justify-left overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ?  'wrapper column width-95 height-10 align-center overflow-xnone overflow-ynone font-300 weight-500 color-800 text-center' : 'wrapper column width-95 height-20 align-center overflow-xnone overflow-ynone font-300 weight-500 text-center' } >
                                Részvételi díj és jelentkezés
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 height-90 align-center overflow-xnone overflow-ynone font-300 weight-600 text-center' : 'wrapper column width-95 height-80 align-center overflow-xnone overflow-ynone font-200 weight-600 text-center' } >
                                Részvételi díj  legalább 50 fő esetén 88.000 Ft
                                <br></br>
                                <br></br>
                                30.000 ft előleget kértem,  mivel szept 25-ig 
                                másfél millió előleget kellett fizetni a szállodának. 
                                Várom a további befizetéseket, hogy ne egyszerre kelljen fizetni.
                                Az indulás előtti 10. napig lehet lemondani az utazást, utána az előleg 
                                nem jár vissza,  ha nem hoz maga helyett új résztvevőt.
                                <br></br>
                                Nagyné Ancikánál 06-20/428-0564 
                                <br></br>
                                vagy Gyüre Lászlónál  42/798-722 vagy 06-30/253-9348 
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- SKUNK --- */}
                    <div id = {width < 480 ? 'programSkunkMobile' : 'programSkunk' }>
                        <div className = 'wrapper column width-100 align-center justify-left overflow-xnone overflow-ynone' >
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-200 weight-400 text-center' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-100 weight-400 ' } >
                            Ajánlott felszerelés: túra vagy sport öltözet, túracipő vagy túrabakancs,  az időjárásnak megfelelően, hátizsák vagy válltáska, esőkabát, vagy esernyő, kalap, túrabot,  1 napi hideg élelem, 1 liter víz, egyéni gyógyszer, zseblámpa, fényképezőgép, mobiltelefon, stb.  Túráink során vadon élő állatok támadására lehet számítani, ( medve, farkas, arany sakál, büdös borz, vaddisznó, róka, szarvas, őz stb.) Az általuk okozott károkért felelősséget nem tudunk vállalni! Kérem ezen tények tudomásul vételét
                                <br></br>
                                <br></br>
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-600 text-center' : 'wrapper column width-95 height-80 align-center overflow-xnone overflow-ynone font-200 weight-600 text-center' } >
                                <br></br>
                                A programváltozás jogát fenntartjuk!
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Program24