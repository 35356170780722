import React, { useEffect, useState, useContext } from 'react'
import UserContext from '../MODELS/USER/UserContext';
import UserType from '../MODELS/USER/User';
import { findOne, doSearch } from '../MODELS/USER/UserModel';
import UserList from '../COMPONENTS/UserList';
import NewEmail from '../COMPONENTS/NewEmail';
import { toast } from 'react-toastify';
import { emailSender, emailSenderTEST } from '../TOOLS/EmailSender';
import { useNavigate } from "react-router-dom";


const AdminView: React.FunctionComponent = (props) => {

    const { users, modifyUsers } = useContext(UserContext);
    const [ width, setWidth ] = React.useState(window.innerWidth);
    const [ userList, setUserList ] = useState<UserType[]>([]);
    const [ showEmail, setShowEmail ] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
       setUserList(users)
     }, [users, showEmail])

  

     const handleSearch = (searchPhrase: string) => { 
        if(searchPhrase !== 'ALL'){
            setUserList(doSearch(users, searchPhrase));
        }else{
            setUserList(users);
        }
    }

    const handleNewEmail = (email: UserType) => { 
        
        emailSender(users, email)
        .then(response => { 
            toast('Emailek elküldve')
            setTimeout(function () { setShowEmail(false) }, 1500);
        })
        .catch(err => { toast('HIBÁK az emailküldés folyamatában! ', err) })
        
        /*
        emailSenderTEST('szempontstudio@gmail.com', email)
        .then(response => { 
            toast('Emailek elküldve')
            setTimeout(function () {
                navigate('/admin')
            }, 1500);
        })
        .catch(err => { toast('HIBÁK az emailküldés folyamatában! ', err) })
        */
     }

     const updateShowEmail = () => { 
        setShowEmail(false)

     }



    return(
        <div className = 'cont row justify-center align-center' id = {width < 480 ? 'backMobile' : 'back' }>
        {width > 1000 || window.screen.orientation.type === 'portrait-primary' ? 
            <div className = {width < 480 ? 'cont width-100 height-100 column justify-center align-center overflow-xnone overflow-ynone' : 'cont column justify-left align-center padding-top-3' } id = {width < 480 ? 'backMobile' : 'back' }>
                <button className = {width < 480 ? 'wrapper button width-40 height-5 font-300 color-200 margin-top-50 ' : 'wrapper button width-10 height-5 margin-bottom-10 color-200'} onClick = {() => navigate('/home')}>Főoldal</button>
                {showEmail ? <NewEmail handleNewEmail = { handleNewEmail } width = { width } updateShowEmail = { updateShowEmail } /> : ''}
                <div className = {width < 480 ? 'wrapper width-60 height-60 column justify-top align-center margin-top-10' : 'wrapper width-90 height-55 row justify-evenly align-top margin-right-1'   }>
                <div id = {width < 480 ? 'personMobile' : 'person' } onClick = {() => navigate('/users')}></div>
                <div id = {width < 480 ? 'emailMobile' : 'email' } onClick = {() => setShowEmail(!showEmail)}></div>
                </div>
            </div>
        : <div id = {width < 480 ? 'backMobile' : 'back' }><div className = 'wrapper width-80 height-30 text-center'><>Fordítsa a telefont álló helyzetbe!</></div></div>}
        </div>
            

    )

}

export default AdminView