import React, { useEffect, useState, useContext } from 'react'

import { useNavigate } from "react-router-dom";




const Program21: React.FunctionComponent = (props) => {

    const [ width, setWidth ] = React.useState(window.innerWidth);

    const navigate = useNavigate();

    useEffect(() => {

    }, [])



    return(
        <div className = 'cont row justify-center align-top overflow-xnone overflow-ynone'  id = {width < 480 ? 'backMobile' : 'back' }>
            <div className = {width < 480 ? 'wrapper column width-90 height-90 align-center overflow-xnone overflow-y padding-top-25 ' : 'wrapper column width-90 height-90 align-center margin-top-5 overflow-xnone overflow-y white'} >
                <div className= {width < 480 ? "wrapper absolute row width-20 height-5 margin-left-50 margin-top-5 justify-center" : "wrapper absolute row width-10 height-5 margin-left-5 margin-top-1 justify-center" }><button className = 'button' onClick = {() => navigate('/')}>vissza</button></div>
                {/* --- OVERFLOW WRAPPER --- */}
                <div className = {width < 480 ? 'wrapper column width-100 height-100 justify-top align-center overflow-y margin-top-20' : 'wrapper column width-70 height-90 justify-top align-center margin-top-5 padding-top-3 margin-left-15 overflow-xnone overflow-y' } >
                    {/* --- HEAD --- */}
                    <div id = {width < 480 ? 'programHeadMobile' : 'programHead' }>
                        <div className = 'wrapper column width-100 align-center justify-top overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 color-800 weight-500 text-center' : 'wrapper column width-95 height-60 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center' }>
                            Brassó és környéke
                            </div>
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center' : 'wrapper column align-center overflow-xnone overflow-ynone font-100 color-800 weight-500 text-center' } >
                                Időpont: 2024. 09. 12 - 16.
                                <br></br>
                                Indulás 05.45-kor a Kert utcai benzinkúttól és 06.00-kor a Búzatéri parkolóból. Hazaérkezés kb 21.00-kor
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- DAY1 --- */}
                    <div id = {width < 480 ? 'programDayMobile' : 'programDay' }>
                        <div className = 'wrapper column width-100 align-center justify-left overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 weight-500 text-center' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-500 text-center' } >
                                PROGRAMOK
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-400 ' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-100 weight-400 ' } >
                                <b>1., nap, szeptember 12, csütörtök: </b>
                                <br></br>
                                Utazás busszal Nyíregyháza - Ártánd - Nagyvárad - Segesvár - Brassó - Bácsfalu(Secele)útvonalon. A szállás elfoglalása barcasági csángó családoknál 2-3 ágyas fürdőszobás házakban, bőséges izletes ételekel.Vacsora 19 óra magyar időben. Útközben Tesco látogatás
                                <br></br>
                                <br></br>
                                <b>2., nap, szeptember 13, péntek:  </b>
                                <br></br>
                                Reggeli 7 órakor magyar időben, busszal indulunk a Bucsecs hegységig ami 35 km-re van. Busteniben kabinos felvonóval felmegyünk 2216 méterre, ahol a kő szfinx-ek találhatóak, lenyűgöző látvány, vissza is kabinnal jövünk. Ezuzán megnézzük a Belesi királyi kastélyt csodálatos környezetben.Belépő árak: 199 Lej. (kedvezményt nem emlitenek.) Vacsora 19 óra. Királyi kastéy emelettől függően 50-150 Lej
                                <br></br>
                                <br></br>
                                <b>3., nap, szeptember 14, szombat:  </b>
                                <br></br>
                                Reggeli után utazás Brassó Pojárára ahol felmegyünk kabinnal. Ott egyénileg gyalog túra lehet a Keresztény havasra (1800 m) Túratáv: 7 km,  szintemelkedés: 400 m. Törcsváron megnézzük a Drakula kastélyt. A felvonó 50 Lej, a kastély 70 Lej (kedvezmény van)
                                <br></br>
                                <br></br>
                                <b>4., nap, szeptember 15, vasárnap :  </b>
                                <br></br>
                                Reggeli után a 7 létrás  vizeséshez megyünk. 2 órás gyalogtúra. Akik nem mennek azoknak séta túra. Ezután vissza brassóba ahol idegenvezetéssel városnézést tervezünk. Este csángó estet tervezünk. Vacsora 19 órakor
                                <br></br>
                                <br></br>
                                <b>5., nap, szeptember 16, hétfő :   </b>
                                <br></br>
                                Reggeli után bepakolás a buszba, hazaindulás. Brassó - Segesvár útvonalon. Közben Segesváron rővid séta a központi várnegyedben. Tartalék program Pázsmári szász erődtemplom Hazaérkezés kb 21 óra
                                <br></br>
                                <br></br>
                                Kedves túratársak, ha lehet a házigazdának vigyünk ajándékot pl 1 üveg bort. Elszabadultak ott is az árak. Ajánlott költőpénz 100 Lej, belépőre 400 Lej. Az útlevelét személyi igazolványát mindenki hozza magával. Eu kártya és balesetbiztosítás és ajánlott.
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- PAY --- */}
                    <div id = {width < 480 ? 'programPayMobile' : 'programPay' }>
                        <div className = 'wrapper column width-100 height-100 align-center justify-left overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ?  'wrapper column width-95 height-10 align-center overflow-xnone overflow-ynone font-300 weight-500 color-800 text-center' : 'wrapper column width-95 height-20 align-center overflow-xnone overflow-ynone font-300 weight-500 text-center' } >
                                Részvételi díj és jelentkezés
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 height-90 align-center overflow-xnone overflow-ynone font-300 weight-600 text-center' : 'wrapper column width-95 height-80 align-center overflow-xnone overflow-ynone font-200 weight-600 text-center' } >
                                Részvételi díj  98.000 Ft, amely függ a létszámtól és a Lej árfolyamtól, a belépőket nem tartalmazza.
                                <br></br>
                                <br></br>
                                Előleg fizetés 60.000 ft a félpanzióra. A többit lehet ott helyben is fizetni 1000 Ft késedelmi díjjal. 7 napon belüli lemondás esetén 25 000ft kártérités, ha nem tud magahelyett valakit szerezni.
                                <br></br>
                                Nagyné Ancikánál 06-20/428-0564 
                                <br></br>
                                vagy Gyüre Lászlónál  42/798-722 vagy 06-30/253-9348 
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- SKUNK --- */}
                    <div id = {width < 480 ? 'programSkunkMobile' : 'programSkunk' }>
                        <div className = 'wrapper column width-100 align-center justify-left overflow-xnone overflow-ynone' >
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-200 weight-400 text-center' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-100 weight-400 ' } >
                            Ajánlott felszerelés: túra vagy sport öltözet, túracipő vagy túrabakancs,  az időjárásnak megfelelően, hátizsák vagy válltáska, esőkabát, vagy esernyő, kalap, túrabot,  1 napi hideg élelem, 1 liter víz, egyéni gyógyszer, zseblámpa, fényképezőgép, mobiltelefon, stb.  Túráink során vadon élő állatok támadására lehet számítani, ( medve, farkas, arany sakál, büdös borz, vaddisznó, róka, szarvas, őz stb.) Az általuk okozott károkért felelősséget nem tudunk vállalni! Kérem ezen tények tudomásul vételét
                                <br></br>
                                <br></br>
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-600 text-center' : 'wrapper column width-95 height-80 align-center overflow-xnone overflow-ynone font-200 weight-600 text-center' } >
                                <br></br>
                                A programváltozás jogát fenntartjuk!
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Program21