import PhotoType from "./Photo"
import { filter, find } from "../Model";


export const findOne = (list: PhotoType[], parameter: string, searchParameter: string) => {
      return (find(list, parameter, searchParameter) as PhotoType);
}

export const filterByParameter = (list: PhotoType[], parameter: string, searchParameter: string) => {
    return (filter (list, parameter, searchParameter) as PhotoType[]) 
}






