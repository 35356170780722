import React, { useEffect, useState, useContext } from 'react'

import { useNavigate } from "react-router-dom";




const Program19: React.FunctionComponent = (props) => {

    const [ width, setWidth ] = React.useState(window.innerWidth);

    const navigate = useNavigate();

    useEffect(() => {

    }, [])



    return(
        <div className = 'cont row justify-center align-top overflow-xnone overflow-ynone'  id = {width < 480 ? 'backMobile' : 'back' }>
            <div className = {width < 480 ? 'wrapper column width-90 height-90 align-center overflow-xnone overflow-y padding-top-25 ' : 'wrapper column width-90 height-90 align-center margin-top-5 overflow-xnone overflow-y white'} >
                <div className= {width < 480 ? "wrapper absolute row width-20 height-5 margin-left-50 margin-top-5 justify-center" : "wrapper absolute row width-10 height-5 margin-left-5 margin-top-1 justify-center" }><button className = 'button' onClick = {() => navigate('/')}>vissza</button></div>
                {/* --- OVERFLOW WRAPPER --- */}
                <div className = {width < 480 ? 'wrapper column width-100 height-100 justify-top align-center overflow-y margin-top-20' : 'wrapper column width-70 height-90 justify-top align-center margin-top-5 padding-top-3 margin-left-15 overflow-xnone overflow-y' } >
                    {/* --- HEAD --- */}
                    <div id = {width < 480 ? 'programHeadMobile' : 'programHead' }>
                        <div className = 'wrapper column width-100 align-center justify-top overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 color-800 weight-500 text-center' : 'wrapper column width-95 height-60 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center' }>
                            Máramaros, Bukovina
                            </div>
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center' : 'wrapper column align-center overflow-xnone overflow-ynone font-100 color-800 weight-500 text-center' } >
                                Időpont: 2024. augusztus 17-20. 4 nap, 3 éjszaka
                                <br></br>
                                Indulás 05.45-kor a Kert utcai benzinkúttól és 06.00-kor a Búzatéri parkolóból. Hazaérkezés kb 20.00-kor
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- DAY1 --- */}
                    <div id = {width < 480 ? 'programDayMobile' : 'programDay' }>
                        <div className = 'wrapper column width-100 align-center justify-left overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 weight-500 text-center' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-500 text-center' } >
                                PROGRAMOK
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-400 ' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-100 weight-400 ' } >
                                <b>1., nap:  </b>
                                <br></br>
                                Indulás Nyíregyházáról 6,00 órakor, határátlépés Vállajnál. A határátlépéshez érvényes személyi igazolványra vagy útlevélre van szükség! A határátlépés után 10 km-re Kaplonyban meglátogatjuk a Károlyi család ősi temetkezési helyét, az Ybl Miklós által tervezett templomot és ferences kolostort. Avasfelsőfaluban (Certeze) elcsodálkozunk a helyiek újkori lakásépítkezési szokásán (a beton átka). Szaploncán a világörökségi „vidám temető” megtekintésére kerül sor. Máramarosszigeten először a Börtön Múzeumot tekintjük meg, majd városnézés a Tisza és Iza torkolatánál lévő városban, mely még megőrzött valamit monarchiabeli állapotából. Utazás szálláshelyünkre az Iza folyó mentén. Vacsora és szállás Borsafüreden a Hotel Cerbul-ban. (3 éjszaka).
                                <br></br>
                                <br></br>
                                <b>2., nap: </b> 
                                <br></br>
                                Reggeli után Bukovinába utazunk – közben rövid pihenőt tartunk a Prislop-hágónál (1415 m): Szentháromság kolostor, Útépítők emlékműve. Utazás az Aranyos-Beszterce folyó mentén igazi Kárpáti tájakon, egyik oldalon az Obcsinák-hegység, másik oldalon a Kelemen-havasok. Először Moldovitában a Szentháromság női kolostort, majd Sucevitában a Feltámadás tiszteletére szentelt kolostort látogatjuk meg. Ezeket a kívül-belül festett, védőfallal körülvett, bástyákkal megerősített kolostorokat moldvai fejedelmek és bojár családok alapították a XIV-XV. században: liturgikus, védelmi és temetkezési célokból. Stílusukat tekintve a moldvai népi építészet keveredik a bizánci és athoszi hatásokkal. Belső tagozódásuk az ortodox építési hagyományokat követi: naosz, pronasz, szentély, ikonosztáz, kupola, dob, csegely, külső és belső festés. Az ortodox hit és azzal összefüggő népi hagyományok, szokások, babonák ötvöződése a hítélet mindennapjaiban. Rövid vásárlási lehetőség a Moldvahosszúmező városában. Visszautazás a szállodába. Vacsora és szállás.                                
                                <br></br>
                                <br></br>
                                <b>3., nap:  </b>
                                <br></br>
                                Felsővisóra utazunk a Visó folyócska völgyében. Félnapos utazás a keskenyvágányú, gőzvontatású Mokanicával vadregényes tájakon a Vasér folyó völgyében. Útvonal Felsővisó állomás – Paltin – Felsővisó állomás, összesen 44 km oda-vissza. A Paltin nevű végállomáson 1 órás tartózkodás alatt megtekintünk egy múzeumot, sétálhatunk, néptánc bemutatót is látunk és grill étel elfogyasztására is van lehetőség. A vonatozás után Borsa városában rövid szabadidőre vagy Borsafüreden rövidebb gyalogtúrára lesz lehetőség. Vacsora és szállás.
                                <br></br>
                                <br></br>
                                <b>4., nap: </b> 
                                <br></br>
                                Reggeli után túra a Lóhavas vízeséshez (első szakasz libegővel, második szakasz gyalog), cca: 2,5-3 óra. Barcánfalván meglátogatjuk a híres kolostoregyüttest (női kolostor, itt található Európa legmagasabb fatornya is). Utazás az Iza folyó területéről a Mara vízgyűjtőjébe: máramarosi fakapuk, fedett keresztek, zsindelyes fatemplomok. Rövid pihenő Desze térségében a „pisztrángosnál”. A Gutin-hágón át érkezünk Nagybányára, városnéző séta: István-torony, Lendvai-ház, Teleki-Magyar ház, református templom, István szálló, Hollósi Simon és társai szoborcsoport (ÚJ), Arany Sas fogadó, Erzsébet-ház. Aranyosmeggyesen fotószünet a község körpontjában a vár romjainál. Határátlépés Vállajnál. Hazaérkezés az esti órákban.
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- PAY --- */}
                    <div id = {width < 480 ? 'programPayMobile' : 'programPay' }>
                        <div className = 'wrapper column width-100 height-100 align-center justify-left overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ?  'wrapper column width-95 height-10 align-center overflow-xnone overflow-ynone font-300 weight-500 color-800 text-center' : 'wrapper column width-95 height-20 align-center overflow-xnone overflow-ynone font-300 weight-500 text-center' } >
                                Részvételi díj és jelentkezés
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 height-90 align-center overflow-xnone overflow-ynone font-300 weight-600 text-center' : 'wrapper column width-95 height-80 align-center overflow-xnone overflow-ynone font-200 weight-600 text-center' } >
                            45 fő esetén 2 és 3 ágyas szobákban 85.000 Ft 1 ágyas szobában 96.000 Ft A részvétei díj tartalmazza a szállást 3 éjszakára és 3 reggelit, valamint 3 vacsorát és az útiköltséget busszal, nem tartalmazza biztosítást, belépődíjakat és kisvonat (Mokanica) díját.

                                <br></br>
                                <br></br>
                                Várható belépődíjak:
-	Kaplony: templom, kolostor, Károlyiak temetkezési helye 10 RON
-	Szaplonca: vidám temető 10 RON
-	Máramarossziget: Börtönmúzeum 15 RON
-	Moldovita és Sucevita kolostorai 20 RON
-	Felsővisó: Mokanica kisvonat 60-70 RON
-	Borsafüred: libegő a Lóhavas-vízeséshez 30-40 RON
-	Barcánfalva kolostoregyüttes: díjtalan
                                <br></br>
                                <br></br>
                                25.000 Ft előleg fizetése a jelentkezéskor. 
                                <br></br>
                                Nagyné Ancikánál 06-20/428-0564 
                                <br></br>
                                vagy Gyüre Lászlónál  42/798-722 vagy 06-30/253-9348 
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- SKUNK --- */}
                    <div id = {width < 480 ? 'programSkunkMobile' : 'programSkunk' }>
                        <div className = 'wrapper column width-100 align-center justify-left overflow-xnone overflow-ynone' >
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-200 weight-400 text-center' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-100 weight-400 ' } >
                            Ajánlott felszerelés: túra vagy sport öltözet, túracipő vagy túrabakancs,  az időjárásnak megfelelően, hátizsák vagy válltáska, esőkabát, vagy esernyő, kalap, túrabot,  1 napi hideg élelem, 1 liter víz, egyéni gyógyszer, zseblámpa, fényképezőgép, mobiltelefon, stb.  Túráink során vadon élő állatok támadására lehet számítani, ( medve, farkas, arany sakál, büdös borz, vaddisznó, róka, szarvas, őz stb.) Az általuk okozott károkért felelősséget nem tudunk vállalni! Kérem ezen tények tudomásul vételét
                                <br></br>
                                <br></br>
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-600 text-center' : 'wrapper column width-95 height-80 align-center overflow-xnone overflow-ynone font-200 weight-600 text-center' } >
                                <br></br>
                                A programváltozás jogát fenntartjuk!
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Program19