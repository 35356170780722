import UserType from '../MODELS/USER/User'
import { loader, modifier, inserter, deleter } from '../TOOLS/CSVHelper'


export const loadUsers = async () => {
    return new Promise<any>(async (resolve, reject) => {
        /*
        getData('hegyibarangolas', 'user', 'hegyibarangolas', 0)
        .then((response: any) => { 
            let answer: any
            answer = (loader(response.data, 'user'))
            resolve(answer)
        })
        .catch(err => { reject(err)})
        */
    })
}


export const usersModifier = async (user: UserType, users: UserType[], action: string) => {
    /*
    let usersShallow: any[] = Object.values(users)
    if(action === 'modify'){
        return new Promise<any>(async (resolve, reject) => {
            saveData(modifier(user, usersShallow, 'user'), 'hegyibarangolas', 'user', 0)
            .then((response: any) => { resolve(response) })
            .catch(err => { reject(err)})
        })
    }else if(action === 'new'){
        
        return new Promise<any>(async (resolve, reject) => {
            saveData(inserter(user, usersShallow, 'user'), 'hegyibarangolas', 'user', 0)
            .then((response: any) => { resolve(response) })
            .catch(err => { reject(err)})
        })
        
    }else if(action === 'delete'){
        console.log('CONTROLLER DELETE ', deleter(user, users, 'user'))

        return new Promise<any>(async (resolve, reject) => {
            saveData(deleter(user, users, 'user'), 'hegyibarangolas', 'user', 0)
            .then((response: any) => { resolve(response) })
            .catch(err => { reject(err)})
        })
        
    }
    */
}



