import React, { useEffect } from 'react';
import DropboxChooser from 'react-dropbox-chooser'


function Dropbox(props) {

  const onSuccess = (files) => {
    const replaced = files[0].link.slice(0, -1) + '1';
    props.handleUpload(replaced);
  }

  const onCancel = () => {
    props.handleUpload('CANCELLED');
  }

    useEffect(() => {

    }, [])

    return(
       
      <>
        <DropboxChooser 
          appKey={process.env.REACT_APP_DROPBOX_APPKEY}
          success={files => onSuccess(files)}
          cancel={() => onCancel()}
          multiselect={true}>
          <button className="wrapper button width-100 height-100 color-200">Feltöltés</button> 
        </DropboxChooser> 
      </>
    
           
      
    )

}

export default Dropbox