import UserType from "../MODELS/USER/User";
import { sendEmail } from "./Queries";
import EmailType from "../MODELS/EMAIL/Email";


export const emailSender = (users: UserType[], email: any) => { 
  let emailProps: any = 'hvbt';
  let delay = 0;
  let sendError = 'NOERROR'

  return new Promise<any>(async (resolve, reject) => {  
  users.forEach(function (user) {
      setTimeout(function () {
          let emailData: EmailType = { from: '', to: user.email, subject: email.subject, text: email.text };
          sendEmail(emailProps, emailData)
          .then(response => {
            if(sendError){
              resolve(sendError)
            }else{
              reject(sendError)
            }
           })
          .catch(err => { sendError = err })
      }, 1000 + delay);
      delay += 1500;
  });
  })

}

export const emailSenderTEST = (user: string, email: any) => { 
  let emailProps: any = 'hvbt';
  let delay = 0;
  let sendError = 'NOERROR'

  return new Promise<any>(async (resolve, reject) => {  
          let emailData: EmailType = { from: '', to: user, subject: email.subject, text: email.text };
          sendEmail(emailProps, emailData)
          .then(response => {
            if(sendError){
              resolve(sendError)
            }else{
              reject(sendError)
            }
           })
          .catch(err => { sendError = err })


  })

}


