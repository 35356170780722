import React, { useEffect, useState, useContext } from 'react'
import EventContext from '../MODELS/EVENT/EventContext';
import UserContext from '../MODELS/USER/UserContext';
import PhotoContext from '../MODELS/PHOTO/PhotoContext';
import IGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { useNavigate, useLocation } from "react-router-dom";
import { filterByParameter } from '../MODELS/PHOTO/PhotoModel';


function ImageGallery() {

  const [ width, setWidth ] = useState(window.innerWidth);
  const { events, event3, updateEvent3 } = useContext(EventContext);
  const { users } = useContext(UserContext);
  const { photos } = useContext(PhotoContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [ images, setImages ] = useState();
  let tempArray = []


  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    console.log('LOCSTATE ', location.state)
    let filtered = filterByParameter(photos, "eventId", location.state)
    filtered.map((photo, index) => {
      let image = {original: photo.name, thumbnail: photo.name};
      tempArray.push(image)
    })

    
    setImages(tempArray)
  }, [])

  const handleCursor = (cursor) => { 

  }

    return(
      <>
      {window.screen.orientation.type === 'landscape-primary' ? 
        <div className = 'cont column justify-left align-center' id = 'backImage'>
          <div className="wrapper absolute z-index-100 row width-10 height-5 justify-center margin-left-80"><button className = 'button' onClick = {() => navigate('/home')}>vissza</button></div>
        {images !== undefined ? <div className="wrapper row  justify-center width-100 height-95"><IGallery  items={images} useBrowserFullscreen = {true} thumbnailPosition = {'left'}/></div> : ''}
        </div>
      : <div className = 'cont column justify-center align-center font-700' id = {width < 480 ? 'backMobile' : 'back' }><div className = 'wrapper width-80 height-10 text-center'><>Fordítsa a telefont fekvő helyzetbe!</></div></div>}
      </>
    )

}

export default ImageGallery