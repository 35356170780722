import { createContext } from 'react';
import Photo from './Photo';
import PhotoInterface from './PhotoInterface';
import PhotoType from './Photo';

const defaultState = {
   photos: [{ id: 0, eventId: 0, name: ''}],
   updatePhotos: (photos: PhotoType[]) => {},
};

const PhotoContext = createContext<PhotoInterface>(defaultState);

export default PhotoContext    