import axios, { AxiosResponse } from 'axios'

type setterType = {
  ftp: string, 
  file: string, 
  payload: any, 
  action: string,
};

type getAllType = {
  event: string, 
  user: string, 
  photo: string 
};

export const getQuery = (getterObject: {}) => {
  let responseObject: any = {data: []}
  return new Promise<any>((resolve, reject) => {
    axios.post<any>('https://homalyzona.herokuapp.com/getter/getter', { getterObject: getterObject })
    //axios.post<any>('http://localhost:3000/getter/getter', { getterObject: getterObject })
      .then((response: AxiosResponse) => { 
        //responseObject.data = decryptList(response.data);
        responseObject.data = response.data;
        resolve(responseObject);
      }).catch(error => { reject(error.response.data); })
  })
};

export const setQuery = (setterObject: setterType, payload: {}) => {
  let shallow = Object.assign({}, payload)
  //let cryptedPayload = encryptObject(payload)
  //setterObject.payload = cryptedPayload
  setterObject.payload = payload
  let responseObject: any = {data: []}
  return new Promise<any>((resolve, reject) => {
    
    axios.post<any>('https://homalyzona.herokuapp.com/setter/setter', { setterObject: setterObject })
    //axios.post<any>('http://localhost:3000/setter/setter', { setterObject: setterObject })
      .then((response: AxiosResponse) => { 
        responseObject.data = response.data;
        resolve(responseObject);
      }).catch(error => { reject(error.response.data); })
      
  })
};

export const getAllCsv = (getterObject: getAllType ) => {
  let responseObject: any = {data: []}
  return new Promise<any>((resolve, reject) => {
    axios.post<any>('https://homalyzona.herokuapp.com/getallhegyibarangolas/load', { getAll: getterObject })
    //axios.post<any>('http://localhost:3000/getallhegyibarangolas/load', { getAll: getterObject })
      .then((response: AxiosResponse) => { 
        responseObject.data = response.data;
        resolve(responseObject);
      }).catch(error => { reject(error.response.data); })
  })
};

export const sendEmail = async (emailProps : any, emailData : any) => {
  return new Promise((resolve, reject) => {
    return axios.post('https://homalyzona.herokuapp.com/homalymail/homalymail', { emailProps : emailProps, emailData : emailData })
    //return axios.post('http://localhost:3000/homalymail/homalymail', { emailProps : emailProps, emailData : emailData })
    .then((response: AxiosResponse) => { resolve(response.status) })
    .catch( error => { reject(error) });
  })
  
};