import React, { useEffect, useState } from 'react';
import { loadUsers, usersModifier } from '../CONTROLLERS/UserController';
import { loadEvents } from './EventController';
import { loadPhotos } from './PhotoController';
import { createErrorMessage } from './ErrorController'
import RoutingController from './RoutingController';
import EventType from '../MODELS/EVENT/Event';
import { setList } from '../MODELS/EVENT/EventModel';
import PhotoType from '../MODELS/PHOTO/Photo';
import UserType from '../MODELS/USER/User';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EventContext from '../MODELS/EVENT/EventContext';
import UserContext from '../MODELS/USER/UserContext';
import PhotoContext from '../MODELS/PHOTO/PhotoContext';
import { getAll } from '../MODELS/Model';

type getAllType = {
  event: string, 
  user: string, 
  photo: any, 
};

const ContextController: React.FunctionComponent = () => {

    const [ loaded, setLoaded ] = useState(false);
   

// ------------------------------------------------------------------------------------------------------------------- USERS
    const [ users, setUsers ] = useState<UserType[]>([]);
    const updateUsers = (user: any) => { setUsers(user) }

     const modifyUsers = (user: UserType, action: string) => {
      let usersShallow: any[] = Object.values(users)
      return new Promise<any>(async (resolve, reject) => {  
        usersModifier(user, usersShallow, action)
        .then(response => {
          setTimeout(() => { 
            /*
            updateUsers();
            if(action === 'modify'){
              toast('A módosítás elmentve!') 
            }else if(action === 'new'){
              toast('A tagot elmentetted!') 
            }else{
              toast('Törölted a tagot!') 
            }
            */
          }, 1000)
        })
        .catch(error => { createErrorMessage(`MODIFYUSERS ERROR: ${error.message}`) })
      })
      
    }
  

// ------------------------------------------------------------------------------------------------------------------- EVENTS
    const [ events, setEvents ] = useState<EventType[]>([]);
    const [ event3, setEvent3 ] = useState<EventType[]>([]);
    const updateEvents = (csvevent: any) => {
      setEvents(csvevent)
      updateEvent3(csvevent)
    }

    const updateEvent3 = (events: EventType[]) => { setEvent3(setList(events)) }

    // ------------------------------------------------------------------------------------------------------------------- PHOTOS
    const [ photos, setPhotos ] = useState<PhotoType[]>([]);
    const updatePhotos = (photos: any) => {
        setPhotos(photos)
  
    }


    useEffect(() =>{
      getAll()
            .then(response => { 
              updateEvents(response.data.event) 
              updateUsers(response.data.user) 
              updatePhotos(response.data.photo) 
  
            })
      .catch(error => { })
      
    }, [loaded])

    return(
        <EventContext.Provider value = {{events, event3, updateEvents, updateEvent3}}>
            <UserContext.Provider value = {{users, updateUsers, modifyUsers}}>
              <PhotoContext.Provider value = {{photos, updatePhotos}}>
                <RoutingController />
                <ToastContainer />
              </PhotoContext.Provider>
            </UserContext.Provider>
        </EventContext.Provider >
    )

}

export default ContextController