import React, { useContext, useState, useEffect } from 'react'
import validator from 'validator'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from "react-router-dom";
import Dropbox from '../TOOLS/Dropbox';
import PhotoContext from '../MODELS/PHOTO/PhotoContext';
import PhotoType from '../MODELS/PHOTO/Photo';
import { photosModifier } from './PhotoController'
import { createErrorMessage } from './ErrorController'

interface IAuthControllerProps {


}

const AuthController: React.FunctionComponent<IAuthControllerProps> = ( props ) => {
 
  const { photos, updatePhotos } = useContext(PhotoContext);
  const [ input, setInput ] = useState({password: ''})
  const [ callDropbox, setCallDropbox ] = useState(false);
  const [ photoEventId, setPhotoEventId ] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const [ width, setWidth ] = React.useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
      if(location.state !== 'admin'){
        setPhotoEventId(location.state)
      }
       
  }, [])
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>):void => {
    setInput({
      ...input,
      [e.target.name]: e.target.value
    })
  }

  const handlePassword = (): void => { 
    if(!input){ 
      return 
    }else if(validator.isEmpty(input.password)){
      toast('A mező nem lehet üres!')
    }else{
      if(input.password === 'hvbt'){
        if(location.state === 'admin'){
          navigate('/admin')
        }else{
          setCallDropbox(true)
        }
        
      }else{
        toast('Nem megfelelő jelszó!')
      }
    }
  }

  const handleUpload = (uploaded: string): void => { 
    let photoToSave: PhotoType = {id: 0, eventId: photoEventId!, name: uploaded};
    if(uploaded !== 'CANCELLED'){
      photosModifier(photoToSave, photos, 'new')
      .then((response: any) => { 
        setTimeout(() => { 
          //updatePhotos();
          toast('Feltöltve!')
        }, 1000) 
      })
      .catch(error => { createErrorMessage(`HANDLEUPLOAD ERROR: ${error.message}`)})
    }
}

  return (
    <>
    <div className = 'cont row justify-center align-center' id = {width < 480 ? 'backMobile' : 'back' }>
      <div className="wrapper justify-center align-top column" id = {width < 480 ? 'loginMobile' : 'login' }>
        {callDropbox ? 
          <div className = 'wrapper width-100 align-center margin-top-2 column'><Dropbox handleUpload = { handleUpload }/></div>
        : 
          <div className = 'wrapper width-100 align-center column'>
            <div className = 'label font-400 color-800'>Adminisztrátori jelszó:</div>
            <input className = "input font-300" type="email" name = 'password' required onChange={handleChange} value = {input.password}></input>
            <button className = {width < 480 ? 'button width-60 font-300' : 'button width-30' }  onClick = {() => handlePassword()}>Kód ellenőrzés</button>
          </div>
        }
      <div className = 'wrapper width-100 align-center margin-top-2 column font-300'><button className = 'button width-30' onClick = {() => navigate('/home')}>vissza</button></div>
      </div>
    </div>
    </>
    );

  }


export default AuthController;
