import UserType from "./User";
import { filter, find } from "../Model";


export const findOne = (list: UserType[], parameter: string, searchParameter: string) => {
  return (find(list, parameter, searchParameter) as UserType);
   
}

export const filterByParameter = (list: UserType[], parameter: string, searchParameter: string) => {
  return (filter (list, parameter, searchParameter) as UserType[]) 
}

export const getUserOptions = (list: UserType[]) => {
  let shallow: UserType[] = list
  let optionList: {value: string, label: string}[] = [];
  shallow.forEach((element) => {
    optionList.push({
      value: element.email,
      label: element.name
    })
  })
  return optionList;
}

export const doSearch = (userList: UserType[], phrase: string) => {
  let answer: UserType[] = [];
  userList.forEach((element: UserType) => { 
    if(element.name.toLowerCase().includes(phrase.toLowerCase()) || element.email.toLowerCase().includes(phrase.toLowerCase())){
      answer.push(element)
    }
  })
  return answer
}


