import React, { useEffect, useState, useContext } from 'react'

import { useNavigate } from "react-router-dom";




const ProgramNo: React.FunctionComponent = (props) => {

    const [ width, setWidth ] = React.useState(window.innerWidth);

    const navigate = useNavigate();

    useEffect(() => {

    }, [])



    return(
        <div className = 'cont row justify-center align-top overflow-xnone overflow-ynone'  id = {width < 480 ? 'backMobile' : 'back' }>
            <div className = {width < 480 ? 'wrapper column width-90 height-90 align-center overflow-xnone overflow-y padding-top-25 ' : 'wrapper column width-90 height-90 align-center margin-top-5 overflow-xnone overflow-y white'} >
                <div className= {width < 480 ? "wrapper absolute row width-20 height-5 margin-left-50 margin-top-5 justify-center" : "wrapper absolute row width-10 height-5 margin-left-5 margin-top-5 justify-center" }><button className = 'button' onClick = {() => navigate('/')}>vissza</button></div>
                {/* --- OVERFLOW WRAPPER --- */}
                <div className = {width < 480 ? 'wrapper column width-100 justify-top align-center overflow-y margin-top-30' : 'wrapper column width-70 justify-top align-center margin-top-10 padding-top-3 margin-left-15 overflow-xnone overflow-ynone' } >
                    {/* --- HEAD --- */}
                    <div id = {width < 480 ? 'programHeadMobile' : 'programHead' }>
                        <div className = 'wrapper column width-100 align-center justify-top overflow-xnone overflow-ynone' >
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 color-800 weight-500 text-center padding-top-3' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center padding-top-3' }>
                                EHHEZ A TÚRÁHOZ MÉG NEM KÉSZÜLT EL A RÉSZLETES PROGRAM...
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ProgramNo