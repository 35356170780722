import React, { useContext, useState, useEffect } from 'react'
import UserContext from '../MODELS/USER/UserContext';
import UserType from '../MODELS/USER/User';
import { toast } from 'react-toastify';
import validator from 'validator';
import { filterByParameter } from '../MODELS/USER/UserModel';
import search from '../ASSETS/search.png'

interface NewUserProps {
    handleNewUser : (user: UserType) => void;
    width: number;
    handleSearch : (phrase: string) => void;
    
}
 
const NewUser: React.FunctionComponent<NewUserProps> = ( props ) => {

    const { users, modifyUsers } = useContext(UserContext);
    const [ formObject, setFormObject ] = useState({ id: 0, name: '', email: ''})
    const [ searchPhrase, setSearchPhrase ] = useState('');

    useEffect(() => {

    }, [])

    const updateState = () => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setFormObject({
            ...formObject,
            [e.target.name]: e.target.value
          })
    };

    const updateSearchState = () => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setSearchPhrase(e.target.value)
      };
    
    const handleForm = (): void => {
        if(!formObject){ 
            return 
        }else if(validator.isEmpty(formObject.name)){
            toast('A név mező nem lehet üres!')
        }else if(validator.isEmpty(formObject.email)){
            toast('Az email mező nem lehet üres!')
        }else if(!validator.isEmail(formObject.email)){
            toast('Nem megfelelő e-mail formátum')
        }else if(checkEMailExist(formObject.email)){
            toast('Ezt az e-mail címet már regisztráltad!')
        }else{
            props.handleNewUser(formObject)
        }

        
    }

    const checkEMailExist = (email: string) => {
        let answer = false;
        if(filterByParameter(users, 'email', email).length > 0){
            answer = true
        }
        return answer
    }


    return ( 
      
        <div className = {props.width < 480 ? 'wrapper width-90 height-80 row justify-center align-top padding-top-10 ' : 'wrapper width-70 height-90 column justify-left align-center margin-top-5 ' } >
            <div id = {props.width < 480 ? 'searchmobile' : 'search' }>
                <div className = {props.width < 480 ? 'wrapper row width-100 height-100 justify-center align-center padding-0' : 'wrapper row width-100 height-100 justify-center align-top '}>
                    <input className = {props.width < 480 ? 'wrapper width-85 font-300 color-500 form-control admin-form-input' : 'wrapper width-80 height-60 font-100 color-500 form-control admin-form-input'} type="text" id="input" name = 'model' placeholder = 'tag keresése' onChange={updateSearchState()}></input>
                    <button className = {props.width < 480 ? 'wrapper button width-10 height-30 color-200 margin-right-4' : 'wrapper button width-10 height-60 color-200 margin-right-5'} onClick = {() => props.handleSearch(searchPhrase)}><img style={{ paddingTop: 5, height: 30, width: 25, }} src={search} alt="?" /></button>
                    <div className = 'wrapper row width-100 justify-center align-center'>
                        <button className = {props.width < 480 ? 'wrapper button width-40 height-100 font-300 color-200' : 'wrapper button width-30 height-90 color-200 margin-top-2'} onClick = {() => props.handleSearch('ALL')}>mindenki</button>
                    </div>
                </div>
            </div>
            <div id = {props.width < 480 ? 'newusermobile' : 'newuser' }>
                <div className = {props.width < 480 ? 'wrapper width-100 height-100 column' : 'wrapper width-100 height-100 row' }>
                    <div className = {props.width < 480 ? 'wrapper width-100 height-90 column padding-0 ' : 'wrapper width-85 height-100 column' } >
                        <div className = {props.width < 480 ? 'wrapper width-100 height-15 font-400 color-800 row padding-left-2' : 'wrapper width-100 height-30 font-200 color-800 row padding-left-2'  } >Új tag rögzítése</div>
                        <div className = {props.width < 480 ? 'wrapper width-100 height-35 column margin-top-3' : 'wrapper width-100 height-35 row' }>
                            <div className = {props.width < 480 ? 'wrapper width-40 height-40 font-400 color-800' : 'wrapper width-20 height-70 font-100 color-500' }> Név </div>
                            <input className = {props.width < 480 ? 'wrapper width-100 height-60 font-300 color-500 form-control admin-form-input' : 'wrapper width-80 height-90 font-100 color-500 form-control admin-form-input' } type="text" id="inputText" name = 'name' value = {formObject.name} required onChange={updateState()}></input>
                        </div>
                        <div className = {props.width < 480 ? 'wrapper width-100 height-35 column margin-top-3' : 'wrapper width-100 height-35 row' }>
                            <div className = {props.width < 480 ? 'wrapper width-40 height-40 font-400 color-800' : 'wrapper width-20 height-70 font-100 color-500' }> email </div>
                            <input className = {props.width < 480 ? 'wrapper width-100 height-60 font-300 color-500 form-control admin-form-input' : 'wrapper width-80 height-90 font-100 color-500 form-control admin-form-input' }type="email" id="inputText" name = 'email' value = {formObject.email} required onChange={updateState()}></input>
                        </div>
                    </div>
                    <div className = {props.width < 480 ? 'wrapper width-100 height-15 row justify-center align-center padding-0' : 'wrapper width-15 height-100 row align-center margin-left-1' }>
                        <button className = {props.width < 480 ? 'wrapper button width-40 height-100 color-200 justify-center font-300' : 'wrapper button width-100 height-15 color-200 justify-center margin-left-2' } onClick = {() => handleForm()}>Mentés</button>
                    </div>
                </div>
            </div>
        </div>
        
     );
}
 
export default NewUser;