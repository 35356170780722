import React, { useEffect, useState, useContext } from 'react'

import { useNavigate } from "react-router-dom";




const Program22: React.FunctionComponent = (props) => {

    const [ width, setWidth ] = React.useState(window.innerWidth);

    const navigate = useNavigate();

    useEffect(() => {

    }, [])



    return(
        <div className = 'cont row justify-center align-top overflow-xnone overflow-ynone'  id = {width < 480 ? 'backMobile' : 'back' }>
            <div className = {width < 480 ? 'wrapper column width-90 height-90 align-center overflow-xnone overflow-y padding-top-25 ' : 'wrapper column width-90 height-90 align-center margin-top-5 overflow-xnone overflow-y white'} >
                <div className= {width < 480 ? "wrapper absolute row width-20 height-5 margin-left-50 margin-top-5 justify-center" : "wrapper absolute row width-10 height-5 margin-left-5 margin-top-1 justify-center" }><button className = 'button' onClick = {() => navigate('/')}>vissza</button></div>
                {/* --- OVERFLOW WRAPPER --- */}
                <div className = {width < 480 ? 'wrapper column width-100 height-100 justify-top align-center overflow-y margin-top-20' : 'wrapper column width-70 height-90 justify-top align-center margin-top-5 padding-top-3 margin-left-15 overflow-xnone overflow-y' } >
                    {/* --- HEAD --- */}
                    <div id = {width < 480 ? 'programHeadMobile' : 'programHead' }>
                        <div className = 'wrapper column width-100 align-center justify-top overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 color-800 weight-500 text-center' : 'wrapper column width-95 height-60 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center' }>
                            Szilvásvárad, Bélapátfalva, Eger-Szépasszonyvölgy
                            </div>
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center' : 'wrapper column align-center overflow-xnone overflow-ynone font-100 color-800 weight-500 text-center' } >
                                Időpont: 2024. 09. 28. szombat
                                <br></br>
                                Indulás 06.45-kor a Kert utcai benzinkúttól és 07.00-kor a Búzatéri parkolóból. Hazaérkezés kb 20.30-kor
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- DAY1 --- */}
                    <div id = {width < 480 ? 'programDayMobile' : 'programDay' }>
                        <div className = 'wrapper column width-100 align-center justify-left overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 weight-500 text-center' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-500 text-center' } >
                                PROGRAMOK
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-400 ' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-100 weight-400 ' } >
                                <b>Rövid túra: </b>
                                <br></br>
                                Séta Szilvásváradon a Szalajka völgyben. Kisvonattal a Fátyol-vízeséshez és vissza gyalog. Közben ebédelési lehetőség, számtalan étterme, ahol sült pisztrángot ehetünk. Meghatározott időben elindulunk Bélapátfalvára, ahol találkozunk a hosszú-túrázókkal. Bélapátfalván megnézzük a Ciszterci Apátsági templomot, majd körbesétáljuk a gyári tavat (itt is van étterem). Hazafelé megállunk a Szépasszonyvölgyben 1-2 pohár borra és vacsorára.
                                <br></br>
                                <br></br>
                                <b>Hosszú túra:</b> 
                                <br></br>
                                Szilvásvárad Fátyol-vízesés - Horotna-völgy - Katona sírok - Bélapátfalvai gyári tó. Túratáv: 13 km, szintemelkedés: 150 m.
                                <br></br>
                                <br></br>
                                <b>Látnivalók Szilvásváradon:</b> 
                                <br></br>
                                Herman Ottó emlékpark, lovas múzeum, Erdődy-Pallavicini kastély, református kerektemplom, Szalajka-vízesés.
                                <br></br>
                                <br></br>
                                <b>Látnivalók Bélapátfalván:</b> 
                                <br></br>
                                Nagyboldogasszony Cisztercita Apátsági templom, hősi emlékmű, Lak-völgyi tó, bélkő tanösvény és hegy
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- PAY --- */}
                    <div id = {width < 480 ? 'programPayMobile' : 'programPay' }>
                        <div className = 'wrapper column width-100 height-100 align-center justify-left overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ?  'wrapper column width-95 height-10 align-center overflow-xnone overflow-ynone font-300 weight-500 color-800 text-center' : 'wrapper column width-95 height-20 align-center overflow-xnone overflow-ynone font-300 weight-500 text-center' } >
                                Részvételi díj és jelentkezés
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 height-90 align-center overflow-xnone overflow-ynone font-300 weight-600 text-center' : 'wrapper column width-95 height-80 align-center overflow-xnone overflow-ynone font-200 weight-600 text-center' } >
                                Részvételi díj: 50 fő esetén 7.000 Ft
                                <br></br>
                                <br></br>
                                3.000 Ft előleg fizetése a jelentkezéskor. 
                                <br></br>
                                Nagyné Ancikánál 06-20/428-0564 
                                <br></br>
                                vagy Gyüre Lászlónál  42/798-722 vagy 06-30/253-9348 
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- SKUNK --- */}
                    <div id = {width < 480 ? 'programSkunkMobile' : 'programSkunk' }>
                        <div className = 'wrapper column width-100 align-center justify-left overflow-xnone overflow-ynone' >
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-200 weight-400 text-center' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-100 weight-400 ' } >
                            Ajánlott felszerelés: túra vagy sport öltözet, túracipő vagy túrabakancs,  az időjárásnak megfelelően, hátizsák vagy válltáska, esőkabát, vagy esernyő, kalap, túrabot,  1 napi hideg élelem, 1 liter víz, egyéni gyógyszer, zseblámpa, fényképezőgép, mobiltelefon, stb.  Túráink során vadon élő állatok támadására lehet számítani, ( medve, farkas, arany sakál, büdös borz, vaddisznó, róka, szarvas, őz stb.) Az általuk okozott károkért felelősséget nem tudunk vállalni! Kérem ezen tények tudomásul vételét
                                <br></br>
                                <br></br>
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-600 text-center' : 'wrapper column width-95 height-80 align-center overflow-xnone overflow-ynone font-200 weight-600 text-center' } >
                                <br></br>
                                A programváltozás jogát fenntartjuk!
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Program22