import React, { useEffect, useState, useContext } from 'react'

import { useNavigate } from "react-router-dom";




const Program1: React.FunctionComponent = (props) => {

    const [ width, setWidth ] = React.useState(window.innerWidth);

    const navigate = useNavigate();

    useEffect(() => {

    }, [])



    return(
        <div className = 'cont row justify-center align-top overflow-xnone overflow-ynone'  id = {width < 480 ? 'backMobile' : 'back' }>
            <div className = {width < 480 ? 'wrapper column width-90 height-90 align-center overflow-xnone overflow-y padding-top-25 ' : 'wrapper column width-90 height-90 align-center margin-top-5 overflow-xnone overflow-y white'} >
                <div className= {width < 480 ? "wrapper absolute row width-20 height-5 margin-left-50 margin-top-5 justify-center" : "wrapper absolute row width-10 height-5 margin-left-5 margin-top-1 justify-center" }><button className = 'button' onClick = {() => navigate('/')}>vissza</button></div>
                {/* --- OVERFLOW WRAPPER --- */}
                <div className = {width < 480 ? 'wrapper column width-100 height-100 justify-top align-center overflow-y margin-top-20' : 'wrapper column width-70 height-90 justify-top align-center margin-top-5 padding-top-3 margin-left-15 overflow-xnone overflow-y' } >
                    {/* --- HEAD --- */}
                    <div id = {width < 480 ? 'programHeadMobile' : 'programHead' }>
                        <div className = 'wrapper column width-100 align-center justify-top overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 color-800 weight-500 text-center' : 'wrapper column width-95 height-60 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center' }>
                            SZILVESZTER ÉSZAK-ZEMPLÉNBEN
                            </div>
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center' : 'wrapper column align-center overflow-xnone overflow-ynone font-100 color-800 weight-500 text-center' } >
                                Időpont: 2023. 12. 29 - péntek
                                <br></br>
                                Indulás 06.45-kor a Kert utcai benzinkúttól és 07.00-kor a Búzatéri parkolóból. Hazaérkezés kb 19.30-kor
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- DAY1 --- */}
                    <div id = {width < 480 ? 'programDayMobile' : 'programDay' }>
                        <div className = 'wrapper column width-100 align-center justify-left overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 weight-500 text-center' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-500 text-center' } >
                                PROGRAMOK
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-400 ' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-100 weight-400 ' } >
                                Hosszú túra: 
                                <br></br>
                                Kőkapu - Rostalló (sárga négyzeten) - Ördög-völgy (sárga sávon) - Bárányirtás - Mlaka-rét - (kék négyzeten tovább) - Szpalanyica völgy - Flórika forrás - Újhuta, Barát Panzió Túratáv: 9 km, szintemelkedés: 200 m.
                                <br></br>
                                <br></br>
                                Rövid túra:
                                <br></br>
                                Újhuta, Barát Panzió - Flórika forrás és vissza: 4 km
                                <br></br>
                                <br></br>
                                Séta túra:
                                <br></br>
                                Kőkaputól Rostallóig és vissza: 2 km, szintemelkedés nincs. Vagy séta Kőkapun: Károlyi vadászkastély, szoborpark, étterem, tó.
                                <br></br>
                                <br></br>
                                A túrák után a tolcsvai Várhegy étteremben fehér asztalnál folytatjuk óév-búcsúztató ünnepségünket. 2 dl forralt bor után 2 féle menüből lehet választani (babgulyás - 2.500 Ft vagy rántott sertéskaraj, sültkrumpli, savanyúság - 2.100 Ft / 2 szelet esetén + 1.300 Ft /). Vacsora után élőzene és tánc teszi hangulatossá szilveszteri bulinkat. Aki nem iszik forralt bort, helyette palackos bort, vagy üdítőt ihat 400 Ft értékben. Az ezen felüli fogyasztás saját költség. Este pezsgőbontással búcsúztatjuk az idei évet. A Baráti Társaság gondoskodikpezsgőről, de egyénileg is lehet hozni pezsgőt, vagy a hölgyek részéről süteményt.
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- PAY --- */}
                    <div id = {width < 480 ? 'programPayMobile' : 'programPay' }>
                        <div className = 'wrapper column width-100 height-100 align-center justify-left overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ?  'wrapper column width-95 height-10 align-center overflow-xnone overflow-ynone font-300 weight-500 color-800 text-center' : 'wrapper column width-95 height-20 align-center overflow-xnone overflow-ynone font-300 weight-500 text-center' } >
                                Részvételi díj és jelentkezés
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 height-90 align-center overflow-xnone overflow-ynone font-300 weight-600 text-center' : 'wrapper column width-95 height-80 align-center overflow-xnone overflow-ynone font-200 weight-600 text-center' } >
                                Részvételi díj  babgulyás vacsorával 8.100 Ft, 1 szeletes rántottkaraj vacsorával 7.800 Ft
                                <br></br>
                                <br></br>
                                3.000 Ft előleg fizetése a jelentkezéskor. Kedves Barátaim, az ünnepség 70 főre lett megrendelve, remélem nem hagyjátok cserben a Baráti Társaságot. Az összeg tartalmazza a buszköltséget, vacsorát, forralt bort, vagy más italt,a zenész és a terem díját.
                                <br></br>
                                <br></br>
                                Nagyné Ancikánál 06-20/428-0564 
                                <br></br>
                                <br></br>
                                vagy Gyüre Lászlónál  42/798-722 vagy 06-30/253-9348 
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- SKUNK --- */}
                    <div id = {width < 480 ? 'programSkunkMobile' : 'programSkunk' }>
                        <div className = 'wrapper column width-100 align-center justify-left overflow-xnone overflow-ynone' >
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-200 weight-400 text-center' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-100 weight-400 ' } >
                                Ajánlott felszerelés: Túra vagy sport öltözet, túracipó vagy túrabakancs,  az időjárásnak megfelelően, hátizsák vagy válltáska, esőkabát, vagy esernyő, kalap, turabot,  1napi hideg élelem, 1 liter víz, egyéni gyógyszer, zseblámpa, fényképezőgép, mobiltelefon, stb. A túrákon mindenki saját felelősségére vesz részt! 
                                <br></br>
                                <br></br>
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-600 text-center' : 'wrapper column width-95 height-80 align-center overflow-xnone overflow-ynone font-200 weight-600 text-center' } >
                                <br></br>
                                A programváltozás jogát fenntartjuk! Áldott, békés Karácsonyt és Boldog Új évet kíván minden vendégnek a Baráti Társaság vezetése.
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Program1