import { createContext } from 'react';
import EventType from './Event';
import EventInterface from './EventInterface';

const defaultState = {
   events: [{ id: 0, date: '', name: '', picture: '', pdf: ''}],
   event3: [{ id: 0, date: '', name: '', picture: '', pdf: ''}],
   updateEvents: (events: EventType[]) => {},
   updateEvent3: (events: EventType[]) => {},
};

const EventContext = createContext<EventInterface>(defaultState);

export default EventContext    