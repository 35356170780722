import React, { useEffect, useState, useContext } from 'react'

import { useNavigate } from "react-router-dom";




const Program16: React.FunctionComponent = (props) => {

    const [ width, setWidth ] = React.useState(window.innerWidth);

    const navigate = useNavigate();

    useEffect(() => {

    }, [])



    return(
        <div className = 'cont row justify-center align-top overflow-xnone overflow-ynone'  id = {width < 480 ? 'backMobile' : 'back' }>
            <div className = {width < 480 ? 'wrapper column width-90 height-90 align-center overflow-xnone overflow-y padding-top-25 ' : 'wrapper column width-90 height-90 align-center margin-top-5 overflow-xnone overflow-y white'} >
                <div className= {width < 480 ? "wrapper absolute row width-20 height-5 margin-left-50 margin-top-5 justify-center" : "wrapper absolute row width-10 height-5 margin-left-5 margin-top-1 justify-center" }><button className = 'button' onClick = {() => navigate('/')}>vissza</button></div>
                {/* --- OVERFLOW WRAPPER --- */}
                <div className = {width < 480 ? 'wrapper column width-100 height-100 justify-top align-center overflow-y margin-top-20' : 'wrapper column width-70 height-90 justify-top align-center margin-top-5 padding-top-3 margin-left-15 overflow-xnone overflow-y' } >
                    {/* --- HEAD --- */}
                    <div id = {width < 480 ? 'programHeadMobile' : 'programHead' }>
                        <div className = 'wrapper column width-100 align-center justify-top overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 color-800 weight-500 text-center' : 'wrapper column width-95 height-60 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center' }>
                            BALATONFELVIDÉK, KESZTHELY
                            </div>
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center' : 'wrapper column align-center overflow-xnone overflow-ynone font-100 color-800 weight-500 text-center' } >
                                Időpont: 2024. 07. 22 - 29.
                                <br></br>
                                Indulás 06.45-kor a Kert utcai benzinkúttól és 07.00-kor a Búzatéri parkolóból. Hazaérkezés kb 20.00-kor
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- DAY1 --- */}
                    <div id = {width < 480 ? 'programDayMobile' : 'programDay' }>
                        <div className = 'wrapper column width-100 align-center justify-left overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 weight-500 text-center' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-500 text-center' } >
                                PROGRAMOK
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-400 ' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-100 weight-400 ' } >
                                <b>1. nap 22-e, hétfő</b>
                                <br></br>
                                KözlekedésNyíregyháza - Keszthely között autópályákon. Útközben Gödöllőn idegenvezetéssel megtekintjük a Grassalkovics királyi kastélyt. Belépő árakat időben közöljük. Budaörsön TESCO vásárlás. Útközben szükség szerinti megállások. Kb. 16 órakor érkezés Keszhelyre a Festetics u. 5 számon levő Pannon egyetem 7 emeletes kollégiumába. Regisztráció után kulcsok kiadása és 2 lifttel 5. és 6. emeletre megyünk. Elhelyezés 3 ágyas szobákban, ahol zuhanyzó, WC, kis hütőszekrény van, valamint erkély. Vacsora 19 órakor.
                                <br></br>
                                <br></br>
                                <b>2. nap 23-a, kedd</b>
                                <br></br>
                                Reggeli 07 órakor az étteremben alagsorban. 8 órakor indulás Zalaszentgrótra a Szent Gróth Termálfürdőbe. Aki nem akar ide jönni mehet Hévizre vagy a Balatonba fürödni. Rossz idő esetén a programot másnapra tesszük át.  Visszafelé rövid séta Hévizen. Gyógyüdülő park, butikok, modern templom, lombkorona tanösvény. Vacsora 19. órakor. Vacsora után fürdési lehetőség a Helikon strandon. /300 m./
                                <br></br>
                                <br></br>
                                <b>3. nap 24-e, szerda</b>
                                <br></br>
                                Reggeli 07 órakor. 8 óra után indulás Pannonhalmára a Főapátság megtekintésére idegenvezetővel. kb 1,5 óra. Az Apátságban étterem is található. A program része a bazilika, altemplom, kerengő és könyvtár egtekintése. Hazafelé Cseszneki várlátogatás és Bakonybélen A csillagda látogatása, Szent lMauriciusz Monostor megtekintése. Huberlaki tó /gyilkos-tó /. Hazaérkezés után 19 órakor vacsora.
                                <br></br>
                                <br></br>
                                <b>4. nap 25-e, csütörtök</b>
                                <br></br>
                                Reggeli 07 órakor. Reggeli után indulás Bogátafürdőre,amely Sumegtől északra van. Több medence van és termál is. Büfé és étterem is van. Visszafelé kb. 1.5 órás látogatást teszünk Sümegen a vár környékén. Sümegi vár, lovarda, püspöki palobában múzeumok, rétesbolt. Ferences szerzetes templom. Vacsora után fürdés lehet.
                                <br></br>
                                <br></br>
                                <b>5. nap 26-a, péntek</b>
                                <br></br>
                                Reggeli után irány Badacsony, ahol terepjárókkal felmehettünk a hegyre és ott rövid sétát tehetünk vagy egy 8 km-es gyalogturát lehet tenni. Fentrők gyönyörü kilátás van a Balatonra. Lefelé gyalog is lehet menni, végig lejt az út. Közben borozók, rétes tészták kaphatók. Rövid sétahajózás is belefér az időbe. Lent éttermek és borozók találhatók. Hazafélé vacsora előtt és után jó idő esetén fürdés a Balatonban. Az egész programunk a fürdésről szól és az egyéb programok lehetőségek a nyaraláshoz!!! Vacsora 19 órakor.
                                <br></br>
                                <br></br>
                                <b>6. nap 27-e, szombat</b>
                                <br></br>
                                Reggeli 07 órakor. Szabadnap a buszvezetőnek így egyéb programok lesznek. Városnézés: szabadfoglalkozás: Festetics kastély, sok múzeum van a városbran: Helikon Park, Vasútmodell múzeum, babamúzeum, Szabó Marcipán múzeum, vadászati múzeum stb. Esténkén a kikötőben kulturális műsorok. 1 órás nappali és éjszakai sétahajózás.Vacsora 19 6rakor, vagy korábban ha lakodalom lesz az étteremben.
                                <br></br>
                                <br></br>
                                <b>7. nap 28-a, vasárnap</b>
                                <br></br>
                                Reggeli 07 órakor. Kirándulás Szlovéniába, Dobronakba Közép-Európa legnagyobb Orchidea telepére és trópusi házba. Belépő 7 Euró (2 Euró levásárolható ajándéktárgyra). Utána látogatás a Bakonaki tóhoz,ahol energia átadó helyek vannak feltöltődésre. Visszafelé meglátogatjuk a Lékai várat. Belépő 5 Euró. Hazaérkezés után 19 órakor vacsora.
                                <br></br>
                                <br></br>
                                <b>8. nap 29-e, hétfő</b>
                                <br></br>
                                Reggeli után bepakolás a buszba és indulás haza . Tihany-Palkod a Tihanyi félsziget gyöngyszeme. Tihanyi Apátság, szép kilátás a Balatonra. bazárok, büfék, stb. Balatonfüreden kb. 2 órát töltünk el, látnivalók Tagore sétány, büfékkel, éttermekkel, Kossuth ivókút, gyógyszálló, Jókai villa, hírességek Panteonja, kikötő, móló stb. Hazafelé szükség szerint állunk meg. A Kerekharaszti pihenőnél ebédelési elehetőség. Hazaérkezés kb. 20 órakor.
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- PAY --- */}
                    <div id = {width < 480 ? 'programPayMobile' : 'programPay' }>
                        <div className = 'wrapper column width-100 height-100 align-center justify-left overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ?  'wrapper column width-95 height-10 align-center overflow-xnone overflow-ynone font-300 weight-500 color-800 text-center' : 'wrapper column width-95 height-20 align-center overflow-xnone overflow-ynone font-300 weight-500 text-center' } >
                                Részvételi díj és jelentkezés
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 height-90 align-center overflow-xnone overflow-ynone font-300 weight-600 text-center' : 'wrapper column width-95 height-80 align-center overflow-xnone overflow-ynone font-200 weight-600 text-center' } >
                                <b>Részvételi díj 50 fő buszos út esetén 137.000 Ft/fő, mely tartalmazza a szállásdíjat, reggelit, vacsorát, buszköltséget, útdíjakat, buszvezető díját.</b>
                                <br></br>
                                <b>Vonattal utazók esetén: 105.000 Ft/fő</b>
                                <br></br>
                                Jelentkezés és befizetés több részletben: 
                                <br></br>
                                Nagyné Ancikánál 06-20/428-0564 
                                <br></br>
                                vagy Gyüre Lászlónál  42/798-722 vagy 06-30/253-9348 
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- SKUNK --- */}
                    <div id = {width < 480 ? 'programSkunkMobile' : 'programSkunk' }>
                        <div className = 'wrapper column width-100 align-center justify-left overflow-xnone overflow-ynone' >
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-200 weight-400 text-center' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-100 weight-400 ' } >
                                Ajánlott felszerelés: kényelmes túracipő, nyári ruházat, sapka vagy kalap, hátizsák vagy válltáska, esőkabát vagy esernyő, napi 1,5 liter víz, napszemüveg, egyéni gyógyszer, fényvédőkrém, mobiltelefon, stb. Személyi igazolvány kötelező. Kb. 20 Euró a belépőkhöz.
                                <br></br>
                                <br></br>
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-600 text-center' : 'wrapper column width-95 height-80 align-center overflow-xnone overflow-ynone font-200 weight-600 text-center' } >
                                <br></br>
                                A programváltozás jogát fenntartjuk!
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Program16