import React from 'react';
import ContextController from './CONTROLLERS/ContextController';

function App() {
  return (
    <ContextController />
  );
}

export default App;
