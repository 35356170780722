import { getAllCsv } from "../TOOLS/Queries";

type getterType = {
  ftp: string, 
  file: string, 
  parameterName: string, 
  parameterValue: string
};

type getAllType = {
  event: string, 
  user: string, 
  photo: string 
};

export const find = (list: any[], parameter: string, searchParameter: string) => {
  let found: any = {};
  list.map((element: any) =>{ 
      //get the parameter name from list keys
      Object.entries(element).forEach((objectElement: any) => {
          //if exist the key by parameter
        if(objectElement[0] === parameter){
          //if key[value] equals to searchparameter
          if(objectElement[1] === searchParameter){
            found = element;
          }
        }
      })
    })  
  return found;
 
}


export const filter = (list: any[], parameter: string, searchParameter: string) => {
    let filteredList: any[] = [];
    list.map((element: any) =>{ 
        //get the parameter name from list keys
        Object.entries(element).forEach((objectElement: any) => {
            //if exist the key by parameter
          if(objectElement[0] === parameter){
            //if key[value] equals to searchparameter
            if(objectElement[1] === searchParameter){
              filteredList.push(element);
            }
          }
        })
      })  
    return filteredList;
   
}

export const getAll = () => {
  let getterObject: getAllType = {event: 'event', user: 'user', photo: 'photo'};
  return new Promise<any>(async (resolve, reject) => {
    getAllCsv(getterObject)
    .then(response => { 
      if(response.length !== 0){ resolve(response)
      }else{ reject('GETTER ERROR') } 
    })
    .catch(error => { reject(error)})
  })
  
};





