import React, { useEffect, useState, useContext } from 'react'

import { useNavigate } from "react-router-dom";




const Program7: React.FunctionComponent = (props) => {

    const [ width, setWidth ] = React.useState(window.innerWidth);

    const navigate = useNavigate();

    useEffect(() => {

    }, [])



    return(
        <div className = 'cont row justify-center align-top overflow-xnone overflow-ynone'  id = {width < 480 ? 'backMobile' : 'back' }>
            <div className = {width < 480 ? 'wrapper column width-90 height-90 align-center overflow-xnone overflow-y padding-top-25 ' : 'wrapper column width-90 height-90 align-center margin-top-5 overflow-xnone overflow-y white'} >
                <div className= {width < 480 ? "wrapper absolute row width-20 height-5 margin-left-50 margin-top-5 justify-center" : "wrapper absolute row width-10 height-5 margin-left-5 margin-top-1 justify-center" }><button className = 'button' onClick = {() => navigate('/')}>vissza</button></div>
                {/* --- OVERFLOW WRAPPER --- */}
                <div className = {width < 480 ? 'wrapper column width-100 height-100 justify-top align-center overflow-y margin-top-20' : 'wrapper column width-70 height-90 justify-top align-center margin-top-5 padding-top-3 margin-left-15 overflow-xnone overflow-y' } >
                    {/* --- HEAD --- */}
                    <div id = {width < 480 ? 'programHeadMobile' : 'programHead' }>
                        <div className = 'wrapper column width-100 align-center justify-top overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 color-800 weight-500 text-center' : 'wrapper column width-95 height-60 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center' }>
                            ERDÉLY - TORDASZENTLÁSZLÓ
                            </div>
                            <div className = {width < 480 ? 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 color-800 weight-500 text-center' : 'wrapper column align-center overflow-xnone overflow-ynone font-100 color-800 weight-500 text-center' } >
                                Időpont: 2024. 03. 29 - 04.01. (4 nap, 3 éjszaka.)
                                <br></br>
                                Indulás 06.45-kor a Kert utcai benzinkúttól és 07.00-kor a Búzatéri parkolóból. Hazaérkezés kb 20.00-kor
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- DAY1 --- */}
                    <div id = {width < 480 ? 'programDayMobile' : 'programDay' }>
                        <div className = 'wrapper column width-100 align-center justify-left overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-400 weight-500 text-center' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-500 text-center' } >
                                PROGRAMOK
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-400 ' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-100 weight-400 ' } >
                                1. nap, 29.-e, péntek
                                <br></br>
                                Indulás Nyíregyházáról. Nagyváradon megtekintjük a nemrégen felújított várat, vásárlás céljából felkeressük a Kaufland áruházat. A következő megálló a Bihar hágó, aholt toalett, büfé, vegyesbolt található. A szálláson 2 és 3 ágyas zuhanyzós szobák várnak bennünket.Vacsora 18 órakor (Román idő szerint 19 órakor)
                                <br></br>
                                <br></br>
                                2. nap, 30-a, szombat
                                <br></br>
                                Reggeli 8 órakor Román idő szerint. Jó idő esetén rövid sétát teszünk a Tordai Hasadékban. Belépő a Nemzeti Parkba 5 Lej. Szintemelkedés/csökkenés: 100 m. A Tordai Hasadék Erdély egyik leglátogatottabb látványossága 2-300 m magas sziklamászó paradicsom, gazdag botanikai rezervátum. Ezután Tordán a sóbányát  nézzük meg, belépő: 80 Lej. Lifttel és falépcsőn lehet lemenni  7 emelet mélyre a nagyon látványos sóbányába.Vacsora 19 órakor Román idő szerint
                                <br></br>
                                <br></br>
                                3. nap, 31-e, vasárnap
                                <br></br>
                                Reggeli 8 órakor Román idő szerint, majd indulunk Torockóra, mely a világörökség része. Torockó Erdély talán legszebb környezetében fekvő községe az impozáns Székelykő lábánál, ahol kétszer kel fel a nap. Néprajzi múzeuma gazdag és látványos, műemlékházak sorakoznak a főtéren ahol Református és Ortodox templom is megfér. Gyalogtúrát teszünk a Torockószentgyörgyi várromhoz. Túratáv 5 km, szintemelkedés: 80 m. Ha időnk engedi, meglátogatjuk a helyi sajtgyárat. Este a vacsoránál kollektív húsvéti locsolkodást szervezünk.
                                <br></br>
                                <br></br>
                                4. nap, 1-4, hétfő
                                <br></br>
                                Reggeli után fél kilenc körül indulás hazafelé. Hazafelé megtekintjük a Havasrekkettyei vízesést. A mennyasszonyfátyla vízesés kb. 30 m magas, kétlépcsős vízesés. Túratáv: 4 km. Hazafelé pihenő a Királyhágón.
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- PAY --- */}
                    <div id = {width < 480 ? 'programPayMobile' : 'programPay' }>
                        <div className = 'wrapper column width-100 height-100 align-center justify-left overflow-xnone overflow-ynone ' >
                            <div className = {width < 480 ?  'wrapper column width-95 height-10 align-center overflow-xnone overflow-ynone font-300 weight-500 color-800 text-center' : 'wrapper column width-95 height-20 align-center overflow-xnone overflow-ynone font-300 weight-500 text-center' } >
                                Részvételi díj és jelentkezés
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 height-90 align-center overflow-xnone overflow-ynone font-300 weight-600 text-center' : 'wrapper column width-95 height-80 align-center overflow-xnone overflow-ynone font-200 weight-600 text-center' } >
                                Részvételi díj  65.000 Ft, mely tartalmazza a 3 éjszakai szállást, félpanziós ellátást és a buszköltséget. Javasolt költőpénz: 120 - 150 Lej.
                                <br></br>
                                <br></br>
                                Jelentkezés: 40.000 Ft előleggel:
                                <br></br>
                                <br></br>
                                Nagyné Ancikánál 06-20/428-0564 
                                <br></br>
                                <br></br>
                                vagy Gyüre Lászlónál  42/798-722 vagy 06-30/253-9348 
                                <br></br>
                                <br></br>
                                Lemondás esetén 7 napon belül 10.000 Ft visszamarad kármentés címén.
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {/* --- SKUNK --- */}
                    <div id = {width < 480 ? 'programSkunkMobile' : 'programSkunk' }>
                        <div className = 'wrapper column width-100 align-center justify-left overflow-xnone overflow-ynone' >
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-200 weight-400 text-center' : 'wrapper column width-95 align-center overflow-xnone overflow-ynone font-100 weight-400 ' } >
                            Ajánlott felszerelés: túra vagy sport öltözet, túracipő vagy túrabakancs,  az időjárásnak megfelelően, hátizsák vagy válltáska, esőkabát, vagy esernyő, kalap, túrabot,  1 napi hideg élelem, 1 liter víz, egyéni gyógyszer, zseblámpa, fényképezőgép, mobiltelefon, stb.  Túráink során vadon élő állatok támadására lehet számítani, ( medve, farkas, arany sakál, büdös borz, vaddisznó, róka, szarvas, őz stb.) Az általuk okozott károkért felelősséget nem tudunk vállalni! Kérem ezen tények tudomásul vételét
                                <br></br>
                                <br></br>
                            </div>
                            <div className = {width < 480 ?  'wrapper column width-95 align-center overflow-xnone overflow-ynone font-300 weight-600 text-center' : 'wrapper column width-95 height-80 align-center overflow-xnone overflow-ynone font-200 weight-600 text-center' } >
                                <br></br>
                                A programváltozás jogát fenntartjuk!
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Program7