import React, { useEffect, useState, useContext } from 'react'
import UserContext from '../MODELS/USER/UserContext';
import UserType from '../MODELS/USER/User';
import { findOne, doSearch } from '../MODELS/USER/UserModel';
import Search from '../COMPONENTS/Search';
import UserList from '../COMPONENTS/UserList';
import NewUser from '../COMPONENTS/NewUser';
import NewEmail from '../COMPONENTS/NewEmail';
import { toast } from 'react-toastify';
import { emailSender } from '../TOOLS/EmailSender';
import { useNavigate } from "react-router-dom";

const Users: React.FunctionComponent = (props) => {

    const { users, modifyUsers } = useContext(UserContext);
    const [ width, setWidth ] = React.useState(window.innerWidth);
    const [ userList, setUserList ] = useState<UserType[]>([]);
    const [ showUsers, setShowUsers ] = useState(false);
    const [ buttonText, setButtonText ] = useState('tagok listája');
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
        if(users.length === 0){
            setUserList(users)
        }
       console.log('USERS UEFF')
    }, [users, buttonText])


     const handleSearch = (searchPhrase: string) => { 
        if(searchPhrase !== 'ALL'){
            setUserList(doSearch(users, searchPhrase));
            show('search');
        }else{
            setUserList(users);
            show('search');
        }
    }

    const handleNewUser = (user: UserType) => { 
       console.log('USER ', user)
       modifyUsers(user, 'new')
    }

     const show = (caller: string) => { 
        if(caller === 'button'){
            setShowUsers(!showUsers)
            setUserList(users)
        }else{
            setShowUsers(!showUsers)
        }
        
        if(buttonText === 'tagok listája'){
            setButtonText('vissza')
        }else{
            setButtonText('tagok listája')
        }
     }



    return(
        <>
        {width > 1000 || window.screen.orientation.type === 'portrait-primary' ? 
            <div className = {width < 480 ? 'cont width-100 column justify-top align-center overflow-xnone overflow-ynone' : 'cont row justify-center align-top overflow-xnone overflow-ynone' } id = {width < 480 ? 'backMobile' : 'back' }>
                 <div className = {width < 480 ? 'wrapper width-100 height-25 row justify-evenly align-bottom ' : 'wrapper width-100 height-10 row justify-center align-top ' }>
                    <button className = {width < 480 ? 'wrapper button width-40 height-25 font-300 color-200 margin-top-3 ' : 'wrapper button width-10 height-45 color-200 margin-right-5'} onClick = {() => navigate('/home')}>Főoldal</button>
                    <button className = {width < 480 ? 'wrapper button width-40 height-25 font-300 color-200 margin-top-3 ' : 'wrapper button width-10 height-45 color-200'} onClick = {() => show('button')}>{ buttonText }</button>
                </div>
                <div className = {width < 480 ? 'wrapper width-100 height-70 row justify-center align-top ' : 'wrapper width-100 height-80 row justify-center align-top ' }>
                {showUsers ? <UserList userList = { userList } width = { width } show = { show } /> : <NewUser handleNewUser = { handleNewUser } width = { width } handleSearch = { handleSearch } />}
                </div>
            </div>
        : <div className = 'cont column justify-center align-center font-700' id = {width < 480 ? 'backMobile' : 'back' }><div className = 'wrapper width-80 height-30 text-center'><>Fordítsa a telefont álló helyzetbe!</></div></div>}
        </>
       
            
    
    )

}

export default Users