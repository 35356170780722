import React, { useEffect, useState, useContext } from 'react'
import UserCard from '../COMPONENTS/UserCard';
import UserType from '../MODELS/USER/User';

interface itemListProps {
    userList: UserType[];
    width: number;
    show : (caller: string) => void;
  }

const ItemList: React.FunctionComponent<itemListProps> = (props) => {


    useEffect(() => {
        console.log('USERLIST UEFF')
    }, [props.userList])

    return(
        <div className = {props.width < 480 ? 'wrapper width-90 height-100 row justify-center align-center margin-top-5 overflow-y color-baseWhite' : 'wrapper width-80 height-100 column justify-left align-center margin-top-1 margin-left-5 overflow-y' } >
        {props.userList.map((element, index) => 
            <div key = { index } className = {props.width < 480 ? 'wrapper width-100 column align-center padding-0' : 'column' } >
               <UserCard user = { element } width = { props.width } show = { props.show }/>
             
            </div>
        )}

        </div>
    )

}

export default ItemList